import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

function MobileMenuItem({ mobileMenu, idx }: any) {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Flex
      key={idx}
      cursor="pointer"
      flexDir="column"
      gap="4px"
      minW="60px"
      onClick={() => navigate(mobileMenu?.path)}
    >
      <Box
        width="100%"
        height="3px"
        borderRadius="0px 0px 12px 12px"
        bg={
          location?.pathname === mobileMenu?.path
            ? "linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
            : "transparent"
        }
        marginBottom="3px"
      ></Box>
      <Image
        src={
          location?.pathname === mobileMenu?.path
            ? mobileMenu?.activeIco
            : mobileMenu?.inActiveIco
        }
        alt={mobileMenu?.name}
        height="24px"
        width="24px"
        mx="auto"
      />
      <Text
        fontSize="10px"
        fontWeight="400"
        textAlign="center"
        color="#7F7F7F"
        bg={
          location?.pathname === mobileMenu?.path
            ? "linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
            : "transparent"
        }
        bgClip={location?.pathname === mobileMenu?.path ? "text" : ""}
      >
        {" "}
        {mobileMenu?.name}
      </Text>
    </Flex>
  );
}

export default MobileMenuItem;
