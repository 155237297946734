import React, { useState } from "react";
import Header from "../components/Header";
import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import homeBg from "../assets/imgs/homeBg.svg";
import downIcon from "../assets/imgs/down_icon.svg";
import monkeyImg from "../assets/imgs/monkey_img.svg";
import Footer from "../components/Footer";
import LeaderboardTable from "../components/LeaderboardTable";
import coins from "../db/Coins.json";

import BuyTable from "../components/BuyTable";

interface TradeData {
  name: string;

  price: string;
  marketCap: string;
  chain: number;
}

const filterHighestReturnTrades = (trades: TradeData[]): TradeData[] => {
  const traderMap: { [key: string]: TradeData } = {};
  trades.forEach((trade) => {
    const traderName = trade.name;
    if (
      !traderMap[traderName] ||
      parseFloat(traderMap[traderName].price) < parseFloat(trade.price)
    ) {
      traderMap[traderName] = trade;
    }
  });
  return Object.values(traderMap);
};

const tableData: TradeData[] = filterHighestReturnTrades(
  coins?.Sheet1?.map((trade: any, index: number) => ({
    name: trade?.name || "123",
    chain: trade?.chain,
    marketCap: trade?.marketCap,
    price: trade?.price,
  })) || []
);

const Buy = () => {
  const [selectedOption, setSelectedOption] = useState("Annual");

  const handleSelectOption = (option: any) => {
    setSelectedOption(option);
  };

  return (
    <Box
      maxW="1680px"
      mx="auto"
      px="20px"
      pb={{ base: "85px", md: "20px" }}
      bgImage={`url(${homeBg})`}
      bgSize="cover"
      bgPosition={"center"}
      bgRepeat="no-repeat"
    >
      <Header />

      <Flex
        justify={"space-between"}
        align={"center"}
        maxW={{ lg: "1405px" }}
        mx={"auto"}
        w={"100%"}
        pt={{ base: "16px", md: "100px" }}
      >
        <Text
          color={"#FFF"}
          fontSize={{ base: "16px", md: "22px", lg: "30px" }}
          letterSpacing={"0.3px"}
          fontFamily={"Abril Fatface"}
        >
          Top Tokens by Market Capitalization
        </Text>
        <Menu isLazy>
          <MenuButton>
            <Flex
              w={{ base: "90px", md: "160px" }}
              p={{ base: "10px 12px", md: "10px 24px" }}
              justifyContent={"space-between"}
              alignItems={"center"}
              bg={"rgba(255,255,255,0.05)"}
              borderRadius={"12px"}
            >
              <Text
                color="#FFF"
                w={"fit-content"}
                // lineHeight="40px"
                fontSize={{ base: "12px", md: "16px" }}
              >
                {selectedOption}
              </Text>
              <Image src={downIcon} />
            </Flex>
          </MenuButton>
          <MenuList
            bg="#1D1E2D"
            p="0px"
            border="none"
            width={{ base: "50px", md: "160px" }}
          >
            <MenuItem
              bg="rgba(255,255,255,0.01)"
              onClick={() => handleSelectOption("Annual")}
            >
              <Flex mr="120px" align="center" gap="4px">
                <Text
                  color="#FFF"
                  lineHeight={{ md: "40px" }}
                  fontSize={{ base: "12px", md: "16px" }}
                >
                  Annual
                </Text>
              </Flex>
            </MenuItem>
            <MenuItem
              bg="rgba(255,255,255,0.01)"
              onClick={() => handleSelectOption("Monthly")}
            >
              <Flex mr="120px" align="center" gap="4px">
                <Text
                  color="#FFF"
                  lineHeight={{ md: "40px" }}
                  fontSize={{ base: "12px", md: "16px" }}
                >
                  Monthly
                </Text>
              </Flex>
            </MenuItem>
            <MenuItem
              bg="rgba(255,255,255,0.01)"
              onClick={() => handleSelectOption("Weekly")}
            >
              <Flex mr="120px" align="center" gap="4px">
                <Text
                  color="#FFF"
                  lineHeight={{ md: "40px" }}
                  fontSize={{ base: "12px", md: "16px" }}
                >
                  Weekly
                </Text>
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <BuyTable data={tableData} rowsPerPage={20} />
      <Footer />
    </Box>
  );
};

export default Buy;
