import { Button, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import SwapCard from "./SwapCard";
import swapArrows from "../assets/imgs/swap_arrows.svg";
import solanaImg from "../assets/imgs/solana_Img.svg";
import cmps from "../assets/imgs/cmps_Img.svg";

const Exchange = () => {
  return (
    <Flex
      mt={{ base: "48px", lg: "150px" }}
      align="center"
      maxW={{ lg: "1405px" }}
      mx="auto"
      flexDir="column"
      p={{ base: "24px", lg: "100px 200px" }}
      gap={{ base: "24px", lg: "60px" }}
      borderRadius="50px"
      //   wrap="wrap"
      bg="rgba(255,255,255,0.05)"
    >
      <Stack align="center" gap="0px">
        <Text
          color="#FFF"
          textAlign="center"
          fontSize={{ base: "12px", lg: "22px" }}
          lineHeight={{ base: "normal", lg: "40px" }}
          textTransform="uppercase"
        >
          Exchange
        </Text>
        <Text
          color="#FFF"
          fontFamily="Abril Fatface"
          textAlign="center"
          fontSize={{ base: "24px", lg: "50px" }}
          letterSpacing={{ base: "0.24px", lg: "0.05px" }}
          lineHeight="normal"
          bgGradient="linear(to-tr, #EE683D, #F38D3F)"
          bgClip="text"
        >
          CMPS Swap
        </Text>
      </Stack>
      <Flex
        justify="flex-end"
        align="center"
        alignSelf="stretch"
        direction={{ base: "column", lg: "row" }}
      >
        <SwapCard text="Sending" icon={solanaImg} name="SOL" />
        <Flex
          p={{ base: "12px", lg: "20px" }}
          flexDirection="column"
          justify="center"
          align="center"
          gap="20px"
          borderRadius="26px"
          border="20px solid #16172B"
          bg="rgba(255,255,255,0.10)"
        >
          <Image
            src={swapArrows}
            alt="swap arrow"
            w={{ base: "40px", lg: "60px" }}
            h={{ base: "40px", lg: "60px" }}
          />
        </Flex>
        <SwapCard text="Receiving" icon={cmps} name="CMPS" />
      </Flex>
      <Flex
        justify="center"
        align="flex-start"
        gap={{ base: "12px", lg: "60px" }}
        alignSelf="stretch"
      >
        <Button
          // maxW={"160px"}
          variant={"unstyled"}
          display="flex"
          p="10px 24px"
          justifyContent="center"
          alignItems="center"
          gap="10px"
          flex={{ lg: "1 0 0" }}
          borderRadius="12px"
          border="1px solid #EE683D"
          fontSize={{ base: "12px", lg: "20px" }}
          lineHeight="40px"
          bgGradient="linear(to-tr, #EE683D, #F38D3F)"
          bgClip="text"
          position="unset"
        >
          Connect Your Wallet
        </Button>
        <Button
          display="flex"
          variant={"unstyled"}
          p="10px 24px"
          justifyContent="center"
          alignItems="center"
          gap="10px"
          flex={{ lg: "1 0 0" }}
          borderRadius="12px"
          bgGradient="linear(to-r, #EE683D, #F38D3F)"
          fontSize={{ base: "12px", lg: "20px" }}
          lineHeight="40px"
          color="#FFF"
          position="unset"
        >
          Swap Now
        </Button>
      </Flex>
    </Flex>
  );
};

export default Exchange;
