import { useQuery } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";

export default function PrivateRoute({
  component,
}: {
  component: JSX.Element;
}) {
  const { data: token } = useQuery({
    queryKey: ["token"],
    queryFn: () => localStorage.getItem("token"),
  });
  if (!token) return <Navigate to="/login" />;
  return component;
}
