import { Flex, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";

function StatCard({ title, number, idx }: any) {
  const [isLessThan360] = useMediaQuery("(max-width: 360px)");
  return (
    <Flex
      bg="rgba(255, 255, 255, 0.05)"
      borderRadius="10px"
      p="24px"
      align="center"
      justify="center"
      height={{ base: "69px", md: "142px" }}
      maxW={{
        base:
          title === "Circulating Supply"
            ? isLessThan360
              ? "100%"
              : "320px"
            : isLessThan360
            ? "100%"
            : "155px",
        md: "248px",
      }}
      w="100%"
      flexDir="column"
      key={idx}
    >
      <Text
        fontSize={{ base: "12px", md: "18px" }}
        fontWeight="400"
        color="#D9D9D9"
        textTransform="uppercase"
      >
        {title}
      </Text>
      <Text
        fontSize={{ base: "20px", md: "40px" }}
        fontWeight="400"
        bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
        bgClip="text"
        fontFamily="Abril Fatface"
      >
        {number}
      </Text>
    </Flex>
  );
}

export default StatCard;
