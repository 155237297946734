import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./privateRoutes";
import { Box } from "@chakra-ui/react";
import Home from "../pages/Home";
import Leaderboard from "../pages/Leaderboard";
import Stakings from "../pages/Stakings";
import Filters from "../pages/Filters";
import Profile from "../components/Profile";
import ProfilePage from "../pages/ProfilePage";
import Followers from "../pages/Followers";
import Settings from "../pages/Settings";
import NewTrade from "../pages/NewTrade";
import Buy from "../pages/Buy";
import TradeDetails from "../pages/TradeDetails";
import Signup from "../pages/Signup";
import Login from "../pages/Login";
import VerifyEmail from "../pages/VerifyEmail";
import ResetPassword from "../pages/ResetPassword";
import WalletOverview from "../pages/WalletOverview";

const routes = [
  {
    path: "/",
    Component: Home,
    isPrivate: false,
  },
  {
    path: "/signup",
    Component: Signup,
    isPrivate: false,
  },
  {
    path: "/login",
    Component: Login,
    isPrivate: false,
  },
  {
    path: "/verifyEmail",
    Component: VerifyEmail,
    isPrivate: false,
  },
  {
    path: "/walletOverview",
    Component: WalletOverview,
    isPrivate: false,
  },
  {
    path: "/resetPassword",
    Component: ResetPassword,
    isPrivate: false,
  },
  {
    path: "/leaderboard",
    Component: Leaderboard,
    isPrivate: false,
  },
  {
    path: "/staking",
    Component: Stakings,
    isPrivate: false,
  },
  {
    path: "/filters",
    Component: Filters,
    isPrivate: false,
  },
  {
    path: "/filters/:id",
    Component: Profile,
    isPrivate: false,
  },
  {
    path: "/profilePage",
    Component: ProfilePage,
    isPrivate: true,
  },
  {
    path: "/followers",
    Component: Followers,
    isPrivate: true,
  },
  {
    path: "/settings",
    Component: Settings,
    isPrivate: false,
  },
  {
    path: "/newTrade",
    Component: NewTrade,
    isPrivate: false,
  },
  {
    path: "/buy",
    Component: Buy,
    isPrivate: false,
  },
  {
    path: "/buy/trade",
    Component: TradeDetails,
    isPrivate: false,
  },
];

export default function AppRoutes() {
  return (
    <Routes>
      {routes?.map(({ path, Component, isPrivate }) => (
        <Route
          key={path}
          element={
            <Box bg="#090A1A" minH="100vh">
              {isPrivate ? (
                <PrivateRoute component={<Component />} />
              ) : (
                <Component />
              )}
            </Box>
          }
          path={path}
        />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
