import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import homeBg from "../assets/imgs/homeBg.svg";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons-react";
import userPic from "../assets/imgs/yellow_profile_pic.svg";
import Footer from "../components/Footer";

const Followers = () => {
  const navigate = useNavigate();
  return (
    <Box
      maxW="1680px"
      mx="auto"
      px="20px"
      pb={{ base: "85px", md: "20px" }}
      bgImage={`url(${homeBg})`}
      bgSize={"cover"}
      bgPosition={"center"}
      bgRepeat="no-repeat"
    >
      <Box display={{ base: "none", md: "block" }}>
        <Header />
      </Box>
      <Box pt={{ base: "16px", md: "100px" }}>
        <Flex
          align="center"
          justify="center"
          flexDir="column"
          maxW="1250px"
          gap="26px"
          mx="auto"
        >
          <Center
            gap={"6px"}
            fontSize={"20px"}
            color={"#FFF"}
            display={{ base: "none", md: "flex" }}
          >
            <Text
              textDecorationLine={"underline"}
              bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
              bgClip="text"
              cursor={"pointer"}
              onClick={() => navigate("/profilePage")}
            >
              Profile
            </Text>
            <Text>/</Text>
            <Text>List of Followers</Text>
          </Center>
          <Flex
            display={{ base: "flex", md: "none" }}
            gap={"6px"}
            fontSize={"14x"}
            color={"#FFF"}
            align={"center"}
            justify={"flex-start"}
            w={"100%"}
          >
            <IconArrowLeft
              cursor={"pointer"}
              onClick={() => navigate("/profilePage")}
            />
            <Text w={"100%"} textAlign={"center"}>
              Followers
            </Text>
          </Flex>
          <Flex
            align="center"
            fontSize={{ base: "24px", md: "32px", lg: "46px", "2xl": "60px" }}
            fontWeight="400"
            gap={{ base: "8px", lg: "12px", "2xl": "18px" }}
            fontFamily="Abril Fatface"
          >
            <Text color="#fff">List of</Text>
            <Text
              bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
              bgClip="text"
            >
              Followers
            </Text>
          </Flex>
        </Flex>
      </Box>
      <SimpleGrid
        minChildWidth={{ base: "154px", md: "296px" }}
        spacing={{ base: "24px", md: "60px" }}
        mt={{ base: "24px", md: "60px" }}
      >
        <FollowerCard pic={userPic} name="Mitch" userName="@mitch" />
        <FollowerCard pic={userPic} name="Mitch" userName="@mitch" />
        <FollowerCard pic={userPic} name="Mitch" userName="@mitch" />
        <FollowerCard pic={userPic} name="Mitch" userName="@mitch" />
        <FollowerCard pic={userPic} name="Mitch" userName="@mitch" />
        <FollowerCard pic={userPic} name="Mitch" userName="@mitch" />
        <FollowerCard pic={userPic} name="Mitch" userName="@mitch" />
        <FollowerCard pic={userPic} name="Mitch" userName="@mitch" />
        <FollowerCard pic={userPic} name="Mitch" userName="@mitch" />
        <FollowerCard pic={userPic} name="Mitch" userName="@mitch" />
      </SimpleGrid>
      <Center>
        <Button
          mt={{ base: "24px", md: "60px" }}
          variant={"unstyled"}
          w={{ base: "96px", md: "150px" }}
          p={"10px 24px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
          borderRadius={{ base: "6px", md: "12px" }}
          bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
          color={"#FFF"}
          fontSize={{ base: "12px", md: "20px" }}
          lineHeight={{ md: "40px" }}
          h={"100%"}
          position="unset"
        >
          Load More
        </Button>
      </Center>
      <Box display={{ base: "none", md: "block" }}>
        <Footer />
      </Box>
    </Box>
  );
};

export default Followers;

const FollowerCard = ({ pic, name, userName }: any) => {
  return (
    <Stack
      p={{ base: "12px", md: "24px" }}
      align={"flex-start"}
      gap={"10px"}
      flex={"1 0 0"}
      borderRadius={"12px"}
      bg={"rgba(255,255,255,0.05)"}
    >
      <Image src={pic} alt="user pic" w={"100%"} />
      <Flex justify={"space-between"} align={"center"} alignSelf={"stretch"}>
        <Text
          color={"#FFF"}
          fontSize={{ base: "16px", md: "22px" }}
          fontWeight={"600"}
          lineHeight={{ md: "40px" }}
        >
          {name}
        </Text>
        <Text
          color={"#FFF"}
          lineHeight={{ md: "40px" }}
          fontSize={{ base: "10px", md: "22px" }}
        >
          {userName}
        </Text>
      </Flex>
    </Stack>
  );
};
