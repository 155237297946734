import { Button, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import stakeImg from "../assets/imgs/stake_img.png";

const Stake = () => {
  return (
    <Flex
      mt={{ base: "48px", lg: "150px" }}
      maxW={"1405px"}
      align={"center"}
      gap={{ base: "16px", lg: "46px" }}
      direction={{ base: "column", lg: "row" }}
    >
      <Image
        src={stakeImg}
        alt="stake"
        w={{ base: "260px", md: "400px", xl: "600px" }}
      />
      <Stack
        align={{ base: "center", lg: "flex-start" }}
        gap={{ base: "12px", lg: "24px" }}
        flex={"1 0 0"}
      >
        <Text
          textAlign={"center"}
          fontSize={{ base: "24px", lg: "50px" }}
          letterSpacing={{ base: "0.24px", lg: "0.5px" }}
          bgClip="text"
          bgGradient="linear(to-r, #EE683D, #F38D3F)"
          fontFamily={"Abril Fatface"}
        >
          Stake $CMPS
        </Text>
        <Text
          alignSelf={"stretch"}
          color={"#FFF"}
          fontSize={{ base: "12px", lg: "22px" }}
          lineHeight={{ base: "normal", lg: "40px" }}
          textAlign={{ base: "center", lg: "start" }}
        >
          Lorem ipsum dolor sit amet consectetur. Est elementum tortor at
          interdum sapien maecenas pretium. Vivamus tristique id cursus nulla
          elit ultrices commodo mollis in. Et bibendum scelerisque tempus quis.
          Fermentum tincidunt augue ipsum sit in porta.
        </Text>
        <Button
          variant={"unstyled"}
          display={"flex"}
          p={"10px 24px"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
          borderRadius={{ base: "6px", lg: "12px" }}
          bgGradient="linear(to-r,#EE683D , #F38D3F)"
          color={"#FFF"}
          fontSize={"20px"}
          lineHeight={"40px"}
          position="unset"
        >
          Stake
        </Button>
      </Stack>
    </Flex>
  );
};

export default Stake;
