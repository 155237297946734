import { useState } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  Text,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Flex,
  Input,
  Button,
  Image,
} from "@chakra-ui/react";
import downIcon from "../assets/imgs/down_icon.svg";
import { IconX } from "@tabler/icons-react";

const SliderCard = ({
  averageReturnText,
  minValue,
  maxValue,
  defaultValue,
  fromSubscription,
  isActive,
  onClick,
}: any) => {
  const [avgValue, setAvgValue] = useState(defaultValue);
  const [filterApplied, setFilterApplied] = useState(false);

  const handleMinClick = () => {
    setAvgValue(minValue);
    setFilterApplied(true);
  };

  const handleMaxClick = () => {
    setAvgValue(maxValue);
    setFilterApplied(true);
  };

  const handleClearFilter = () => {
    setAvgValue(defaultValue);
    setFilterApplied(false);
  };

  return (
    <Menu isLazy>
      <MenuButton
        onClick={() => {
          if (filterApplied) {
            handleClearFilter();
          } else {
            onClick();
          }
        }}
      >
        <Flex
          p={{ base: "10px 12px", md: "10px 18px", "2xl": "10px 16px" }}
          justifyContent={"space-between"}
          alignItems={"center"}
          bg={
            filterApplied
              ? "var(--Gradient, linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%))"
              : "rgba(255,255,255,0.05)"
          }
          borderRadius={"12px"}
        >
          <Text
            color="#FFF"
            w={"fit-content"}
            fontSize={{ base: "12px", md: "14px", "2xl": "16px" }}
          >
            {filterApplied ? `Min:  ${avgValue}` : averageReturnText}
          </Text>
          {filterApplied ? (
            <Button variant="unstyled" h={"100%"}>
              <IconX color="#FFF" />
            </Button>
          ) : (
            <Image src={downIcon} />
          )}
        </Flex>
      </MenuButton>
      <MenuList bg="#1D1E2D" p="12px" border="none">
        <Text
          color={"#FFF"}
          fontSize={{ base: "10px", md: "13px", xl: "16px" }}
          fontWeight={"400"}
          lineHeight={"40px"}
          mb={fromSubscription ? "" : "20px"}
        >
          {averageReturnText}
        </Text>
        {fromSubscription && (
          <Flex mb="40px" gap={"5px"} align={"center"}>
            <Button
              display="flex"
              variant={"unstyled"}
              padding={{ base: "5px 12px", md: "7px 16px", xl: "10px 24px" }}
              justifyContent="center"
              alignItems="center"
              gap="10px"
              flex={{ lg: "1 0 0" }}
              borderRadius="12px"
              bgGradient="linear(to-r, #EE683D, #F38D3F)"
              fontSize={{ base: "10px", md: "13px", xl: "16px" }}
              lineHeight={{ md: "40px" }}
              color="#FFF"
              position="unset"
              h={"40px"}
              // onClick={handleMinClick}
            >
              Free
            </Button>
            <Button
              display="flex"
              variant={"unstyled"}
              padding={{ base: "5px 12px", md: "7px 16px", xl: "10px 24px" }}
              justifyContent="center"
              alignItems="center"
              gap="10px"
              flex={{ lg: "1 0 0" }}
              borderRadius="12px"
              bgGradient="linear(to-r, #EE683D, #F38D3F)"
              fontSize={{ base: "10px", md: "13px", xl: "16px" }}
              lineHeight={{ md: "40px" }}
              color="#FFF"
              position="unset"
              h={"40px"}
              // onClick={handleMaxClick}
            >
              Any
            </Button>
          </Flex>
        )}
        <Slider
          aria-label="slider-ex-6"
          defaultValue={0}
          colorScheme="green"
          max={maxValue}
          min={minValue}
          value={avgValue}
          focusThumbOnChange={false}
          onChange={(val) => {
            setAvgValue(val);
            setFilterApplied(true);
          }}
        >
          <SliderMark
            value={avgValue}
            textAlign="center"
            mt={"-20px"}
            display={avgValue > 100 || avgValue < -100 ? "none" : "block"}
          >
            <div
              style={{
                position: "relative",
                display: "inline-block",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-40px",
                  // left: "60%",
                  transform: "translateX(-50%)",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    background: "#090A1A",
                    borderRadius: "20px",
                    color: "#FFF",
                    padding: "4px 12px",
                    fontSize: "12px",
                    fontWeight: "700",
                    letterSpacing: "0.048px",
                    position: "relative",
                  }}
                >
                  {Number(avgValue || 0)?.toLocaleString()}
                </div>
              </div>
            </div>
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack bg={"#00FFE0"} />
          </SliderTrack>
          <SliderThumb
            display={"flex"}
            w={"18px"}
            h="18px"
            p={"4px"}
            alignItems={"flex-start"}
            gap={"2.667px"}
            borderRadius={"32px"}
            border={"1.333px solid #000"}
            bg={"#FFF"}
            zIndex={0}
          ></SliderThumb>
        </Slider>

        {/* Slider Track, Slider Filled Track, Slider Thumb */}

        <Flex
          justify={"space-between"}
          w={"100%"}
          color={"#FFF"}
          //   mt={"-10px"}
          mb={"10px"}
          fontSize={{ base: "10px", md: "13px", xl: "16px" }}
        >
          <Text>{minValue}</Text>
          <Text>{maxValue}</Text>
        </Flex>

        <Input
          placeholder="0"
          variant="unstyled"
          color="#FFF"
          fontSize={{ base: "10px", md: "13px", xl: "16px" }}
          lineHeight={{ md: "40px" }}
          _placeholder={{
            color: "#FFF",
          }}
          display="flex"
          h={"40px"}
          min={minValue}
          max={maxValue}
          padding={{ base: "5px 12px", md: "7px 16px", xl: "10px 24px" }}
          justifyContent="space-between"
          alignItems="center"
          borderRadius={{ base: "4px", md: "12px" }}
          bg="rgba(255,255,255,0.05)"
          value={avgValue}
          type="number"
          onChange={(e: any) => {
            setAvgValue(e.target.value);

            setFilterApplied(true);
          }}
        />

        <Flex mt={"10px"} gap={"5px"} align={"center"}>
          <Button
            display="flex"
            variant={"unstyled"}
            padding={{ base: "5px 12px", md: "7px 16px", xl: "10px 24px" }}
            justifyContent="center"
            alignItems="center"
            gap="10px"
            flex={{ lg: "1 0 0" }}
            borderRadius="12px"
            bgGradient="linear(to-r, #EE683D, #F38D3F)"
            fontSize={{ base: "10px", md: "13px", xl: "16px" }}
            lineHeight={{ md: "40px" }}
            color="#FFF"
            position="unset"
            h={"40px"}
            onClick={handleMinClick}
          >
            Min
          </Button>
          <Button
            display="flex"
            variant={"unstyled"}
            padding={{ base: "5px 12px", md: "7px 16px", xl: "10px 24px" }}
            justifyContent="center"
            alignItems="center"
            gap="10px"
            flex={{ lg: "1 0 0" }}
            borderRadius="12px"
            bgGradient="linear(to-r, #EE683D, #F38D3F)"
            fontSize={{ base: "10px", md: "13px", xl: "16px" }}
            lineHeight={{ md: "40px" }}
            color="#FFF"
            position="unset"
            h={"40px"}
            onClick={handleMaxClick}
          >
            Max
          </Button>
        </Flex>
      </MenuList>
    </Menu>
  );
};

export default SliderCard;
