import { Box, Center, Flex, Image, Input, Stack, Text } from "@chakra-ui/react";
import React from "react";
import homeBg from "../assets/imgs/homeBg.svg";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons-react";
import Footer from "../components/Footer";
import toggleIcon from "../assets/imgs/toggleOff.svg";

const Settings = () => {
  const navigate = useNavigate();
  return (
    <Box
      maxW="1680px"
      mx="auto"
      px="20px"
      pb={{ base: "85px", md: "20px" }}
      bgImage={`url(${homeBg})`}
      //   bgSize={"cover"}
      bgPosition={"center"}
      bgRepeat="no-repeat"
    >
      <Box display={{ base: "none", md: "block" }}>
        <Header />
      </Box>
      <Box pt={{ base: "16px", md: "100px" }}>
        <Flex
          align="center"
          justify="center"
          flexDir="column"
          maxW="1250px"
          gap="26px"
          mx="auto"
        >
          <Center
            gap={"6px"}
            fontSize={"20px"}
            color={"#FFF"}
            display={{ base: "none", md: "flex" }}
          >
            <Text
              textDecorationLine={"underline"}
              bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
              bgClip="text"
              cursor={"pointer"}
              onClick={() => navigate("/profilePage")}
            >
              Profile
            </Text>
            <Text>/</Text>
            <Text>Settings</Text>
          </Center>
          <Flex
            display={{ base: "flex", md: "none" }}
            gap={"6px"}
            fontSize={"14x"}
            color={"#FFF"}
            align={"center"}
            justify={"flex-start"}
            w={"100%"}
          >
            <IconArrowLeft
              cursor={"pointer"}
              onClick={() => navigate("/profilePage")}
            />
            <Text w={"100%"} textAlign={"center"}>
              Settings
            </Text>
          </Flex>
          <Flex
            align="center"
            fontSize={{ base: "24px", md: "32px", lg: "46px", "2xl": "60px" }}
            fontWeight="400"
            gap={{ base: "8px", lg: "12px", "2xl": "18px" }}
            fontFamily="Abril Fatface"
          >
            <Text color="#fff">Settings</Text>
          </Flex>
        </Flex>
      </Box>
      <Stack
        maxW={"1250px"}
        mx={"auto"}
        align={"flex-start"}
        gap={{ base: "24px", md: "60px" }}
        alignSelf={"stretch"}
        mt={{ base: "24px", md: "90px" }}
      >
        <SettingCard
          text="Auto Buy & Sell"
          toggleIcon={toggleIcon}
          isToggle={true}
        />
        <SettingCard
          text="Notification for Email"
          toggleIcon={toggleIcon}
          isToggle={true}
        />
        <SettingCard
          text="Notification for Phone Number"
          toggleIcon={toggleIcon}
          isToggle={true}
        />
        <SettingCard
          text="Notification for Telegram"
          toggleIcon={toggleIcon}
          isToggle={true}
        />
        <SettingCard
          text="Gas Fee"
          toggleIcon={toggleIcon}
          isToggle={false}
          percent="$5"
        />
        <SettingCard
          text="Slippage"
          toggleIcon={toggleIcon}
          isToggle={false}
          percent="10%"
        />
      </Stack>
      {/* <Box display={{ base: "none", md: "block" }}> */}
      <Footer />
      {/* </Box> */}
    </Box>
  );
};

export default Settings;

const SettingCard = ({ text, toggleIcon, isToggle, percent }: any) => {
  return (
    <Flex
      p={"24px"}
      justify={"space-between"}
      align={"center"}
      w={"100%"}
      alignSelf={"stretch"}
      borderRadius={"12px"}
      bg={"rgba(255,255,255,0.05)"}
    >
      <Text
        color={"#FFF"}
        fontSize={{ base: "14px", md: "22px" }}
        lineHeight={"40px"}
      >
        {text}
      </Text>
      {isToggle ? (
        <Image
          src={toggleIcon}
          alt="toggleIcon"
          w={{ base: "46px", md: "64px" }}
        />
      ) : (
        <Input
          variant={"unstyled"}
          display={"flex"}
          w={{ base: "60px", md: "150px" }}
          p={{ base: "2px 12px", md: "10px 24px" }}
          alignItems={"center"}
          gap={"10px"}
          borderRadius={{ base: "4px", md: "12px" }}
          bg={"rgba(255,255,255,0.05)"}
          placeholder={percent}
          color={"#FFF"}
          fontSize={{ base: "10px", md: "20px" }}
          lineHeight={{ base: "25px", md: "40px" }}
          _placeholder={{ color: "#FFF" }}
        />
      )}
    </Flex>
  );
};
