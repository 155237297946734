import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import Avatar from "../assets/imgs/avatar.svg";
import SolIco from "../assets/imgs/sol_ico.svg";
import { useNavigate } from "react-router-dom";

const STATS = [
  {
    id: 2,
    title: "MItch_101",
    stats: "+6749%",
  },
  {
    id: 3,
    title: "MItch_102",
    stats: "+3749%",
  },
  {
    id: 4,
    title: "MItch_201",
    stats: "+3749%",
  },
  {
    id: 5,
    title: "MItch_136",
    stats: "+1749%",
  },
  {
    id: 6,
    title: "MItch_305",
    stats: "+749%",
  },
];

function RetainerCard({ title }: any) {
  const navigate = useNavigate();

  return (
    <Box
      p="24px 24px 30px 14px"
      borderRadius="26px"
      bg="rgba(255,255,255,0.05)"
      maxW="450px"
      minW={{ base: "280px", sm: "320px" }}
      width="100%"
      h={{ base: "580px", md: "780px" }}
      pos="relative"
    >
      <Text
        fontSize={{ base: "24px", md: "30px", lg: "40px" }}
        fontWeight="400"
        fontFamily="Abril Fatface"
        textAlign="center"
        bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
        bgClip="text"
      >
        {" "}
        {title}
      </Text>
      <Flex mt={{ base: "12px", md: "20px" }} align="center">
        <Flex
          bg="#252634"
          borderRadius={{ base: "6px", md: "12px" }}
          alignItems="center"
          justifyContent="center"
          height={{ base: "26px", md: "40px" }}
          width={{ base: "26px", md: "40px" }}
          mr="-15px"
        >
          <Text
            fontSize={{ base: "12px", md: "18px" }}
            fontWeight="600"
            color="#fff"
          >
            1
          </Text>
        </Flex>
        <Flex
          p={{ base: "6px 24px", md: "12px 24px" }}
          align="center"
          justify="space-between"
          gap="16px"
          bg="rgba(255, 255, 255, 0.02)"
          borderRadius="16px"
          w="100%"
        >
          <Flex gap="16px" align="center">
            <Flex
              align="center"
              justify="center"
              bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
              height={{ base: "50px", md: "80px" }}
              width={{ base: "50px", md: "80px" }}
              borderRadius={{ base: "12px", md: "20px" }}
            >
              <Image src={Avatar} alt="img" />
            </Flex>
            <Flex align="start" flexDir="column">
              <Text
                color="#7F7F7F"
                fontSize={{ base: "10px", md: "18px" }}
                fontWeight="400"
              >
                Name
              </Text>
              <Text
                color="#fff"
                fontWeight="600"
                fontSize={{ base: "14px", md: "22px" }}
              >
                Mitch
              </Text>
            </Flex>
          </Flex>
          <Flex align="end" flexDir="column">
            <Flex align="center" gap="4px">
              <Image
                src={SolIco}
                alt="sol"
                width={{ base: "20px", md: "28px" }}
                height={{ base: "20px", md: "28px" }}
              />
              <Text
                fontSize={{ base: "10px", md: "16px" }}
                fontWeight="400"
                color="#fff"
              >
                SOL
              </Text>
            </Flex>
            <Text
              color="#00CD08"
              fontSize={{ base: "10px", md: "18px" }}
              fontWeight="400"
              textAlign="right"
            >
              150%
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {STATS?.map((stat, idx) => (
        <Flex mt={{ base: "12px", md: "20px" }} align="center" key={idx}>
          <Flex
            bg="#252634"
            borderRadius={{ base: "6px", md: "12px" }}
            alignItems="center"
            justifyContent="center"
            height={{ base: "26px", md: "40px" }}
            width={{ base: "26px", md: "40px" }}
            mr="-15px"
          >
            <Text
              fontSize={{ base: "12px", md: "18px" }}
              fontWeight="600"
              color="#fff"
            >
              {stat?.id}
            </Text>
          </Flex>
          <Flex
            p="12px 24px"
            align="center"
            justify="space-between"
            gap="16px"
            bg={
              Number(stat?.id) % 2 !== 0
                ? "rgba(255, 255, 255, 0.02)"
                : "transparent"
            }
            borderRadius="16px"
            w="100%"
          >
            <Flex align="center" flexDir="column" mx="auto">
              <Text
                fontSize={{ base: "12px", md: "16px" }}
                fontWeight="400"
                color="#7F7F7F"
              >
                {stat?.title}
              </Text>
              <Text
                color="#fff"
                fontSize={{ base: "16px", md: "22px" }}
                fontWeight="600"
              >
                {stat?.stats}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      ))}
      <Button
        pos="absolute"
        zIndex={"23"}
        color="#fff"
        p={{ base: "10px 32px", md: "30px 34px" }}
        mt={{ base: "10px", md: "-5px" }}
        borderRadius={{ base: "6px", md: "12px" }}
        fontSize={{ base: "12px", md: "20px" }}
        fontWeight="400"
        bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
        _hover={{
          bg: "linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)",
        }}
        left="50%"
        transform="translate(-50%)"
        onClick={() => navigate("/leaderboard")}
      >
        View Full leaderboard
      </Button>
    </Box>
  );
}

export default RetainerCard;
