import { Text } from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

function MenuItem({ menu, idx }: any) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Text
      key={idx}
      color="#fff"
      fontSize={{ md: "16px", "2xl": "20px" }}
      fontWeight="400"
      cursor="pointer"
      bg={
        location?.pathname === menu?.path
          ? "linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
          : ""
      }
      bgClip={location?.pathname === menu?.path ? "text" : ""}
      pb="10px"
      borderBottom={
        location?.pathname === menu?.path
          ? "2px solid #EE683D"
          : "2px solid transparent"
      }
      onClick={() => navigate(menu?.path)}
    >
      {menu?.name}
    </Text>
  );
}

export default MenuItem;
