import React, { useState } from "react";
import Footer from "../components/Footer";
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import homeBg from "../assets/imgs/homeBg.svg";
import Header from "../components/Header";
import { IconSearch } from "@tabler/icons-react";
import FilterTable from "../components/FilterTable";
import monkeyImg from "../assets/imgs/monkey_img.svg";
import { useNavigate } from "react-router-dom";
import SliderCard from "../components/SliderCard";
import openTrades from "../db/open trades.json";

const tableData = openTrades?.Sheet1?.map((trade: any, index: any) => ({
  trader: {
    imageSrc: monkeyImg,
    name: trade?.Trader,
  },
  name: trade?.Trader,
  followers: "123",
  trades: "15",
  bestTrade: "156%",
  worstTrade: "-56%",
  winRate: "39%",
  holdTime: trade["Current Hold Time (Days)"],
  return: trade?.Return,
  subscriptionPrice: "200",
}));

const Filters = () => {
  const navigate = useNavigate();

  const [activeButtons, setActiveButtons] = useState<any[]>([]);

  const handleButtonClick = (buttonName: any) => {
    if (activeButtons.includes(buttonName)) {
      setActiveButtons(activeButtons.filter((name) => name !== buttonName));
    } else {
      setActiveButtons([...activeButtons, buttonName]);
    }
  };

  return (
    <Box
      maxW="1680px"
      mx="auto"
      px="20px"
      pb={{ base: "85px", md: "20px" }}
      bgImage={`url(${homeBg})`}
      bgSize={"contain"}
      bgPosition={"center"}
      bgRepeat="no-repeat"
    >
      <Header />
      <Box pt={{ base: "16px", md: "100px" }}>
        <Flex
          align="center"
          justify="center"
          flexDir="column"
          maxW="1250px"
          gap="16px"
          mx="auto"
        >
          <Flex
            align="center"
            fontSize={{ base: "24px", md: "32px", lg: "46px", "2xl": "60px" }}
            fontWeight="400"
            gap={{ base: "8px", lg: "12px", "2xl": "18px" }}
            fontFamily="Abril Fatface"
          >
            <Text color="#fff">Filters</Text>
          </Flex>
          <Text
            fontSize={{ base: "12px", md: "18px", lg: "22px" }}
            fontWeight="400"
            color="#fff"
            textAlign="center"
          >
            Lorem ipsum dolor sit amet consectetur. Eget viverra arcu elementum
            tellus tortor facilisi nisl leo purus. Pulvinar vitae donec donec
            aenean ultrices vitae interdum laoreet non.
          </Text>
        </Flex>
      </Box>
      {/* <Flex
        w={"100%"}
        gap={{ base: "12px", md: "40px" }}
        align={"center"}
        mt={"100px"}
        flexWrap={"wrap"}
      > */}
      <SimpleGrid
        // columns={[2, 3, 5, 6, 7]}
        minChildWidth={"171px"}
        spacing={{ base: "12px", md: "24px", "2xl": "40px" }}
        mt={"100px"}
        alignItems={"center"}
      >
        <Flex
          // w={{ base: "90px", md: "247px" }}
          p={{ base: "10px 12px", md: "10px 18px", "2xl": "10px 24px" }}
          justifyContent="space-between"
          alignItems="center"
          bg="rgba(255,255,255,0.05)"
          borderRadius="12px"
        >
          <Input
            variant="unstyled"
            color="white"
            placeholder="Search by Name"
            _placeholder={{ color: "white" }}
            fontSize={{ base: "12px", md: "16px", "2xl": "16px" }}
            _focus={{ outline: "none" }}
          />
          <IconSearch color="rgba(127, 127, 127, 1)" />
        </Flex>

        {[
          { text: "Avg Return", min: -100, max: 100, default: 0 },
          { text: "Followers", min: 0, max: 1000, default: 0 },
          { text: "Avg Hold Time", min: 0, max: 365, default: 365 },
          { text: "Trades", min: 1, max: 100, default: 1 },
          { text: "Win Rate", min: 0, max: 100, default: 0 },
          {
            text: "Subscription",
            min: 0,
            max: 10000,
            default: 0,
            fromSubscription: true,
          },
        ].map((slider, index) => (
          <SliderCard
            key={index}
            averageReturnText={slider.text}
            minValue={slider.min}
            maxValue={slider.max}
            defaultValue={slider.default}
            isActive={activeButtons.includes(slider.text)}
            fromSubscription={slider.fromSubscription}
            onClick={() => handleButtonClick(slider.text)}
          />
        ))}
        <Center display={{ xl: "none" }}>
          <Button
            variant={"unstyled"}
            display={"flex"}
            p={{ base: "10px 12px", md: "10px 18px", "2xl": "10px 24px" }}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"10px"}
            borderRadius={"12px"}
            bg="var(--Gradient, linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%))"
            color={"#FFF"}
            fontSize={{ base: "12px", md: "16px", "2xl": "20px" }}
            lineHeight={{ md: "40px" }}
            w={{ base: "100%", sm: "100%", md: "100%" }}
            position="unset"
            // w={{ base: "200%", md: "100%  " }}
          >
            Apply Filters
          </Button>
        </Center>
      </SimpleGrid>
      <Center display={{ base: "none", xl: "flex" }}>
        <Button
          variant={"unstyled"}
          display={"flex"}
          p={{ base: "10px 12px", md: "10px 18px", "2xl": "10px 24px" }}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
          mt={"20px"}
          borderRadius={"12px"}
          bg="var(--Gradient, linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%))"
          color={"#FFF"}
          fontSize={{ base: "12px", md: "16px", "2xl": "20px" }}
          lineHeight={{ md: "40px" }}
          position="unset"
          // w={{ base: "200%", md: "100%  " }}
        >
          Apply Filters
        </Button>
      </Center>
      {/* </Flex> */}
      <FilterTable
        data={tableData}
        rowsPerPage={10}
        onClick={() => navigate("/filters/1")}
      />
      <Footer />
    </Box>
  );
};

export default Filters;

// const FilterCard = ({ options, defaultOption }: any) => {
//   const [selectedOption, setSelectedOption] = useState(defaultOption);

//   const handleSelectOption = (option: any) => {
//     setSelectedOption(option);
//   };

//   return (
//     <Menu isLazy>
//       <MenuButton>
//         <Flex
//           // w={{ base: "90px", md: "160px" }}
//           p={{ base: "10px 12px", md: "10px 18px", "2xl": "10px 24px" }}
//           justifyContent={"space-between"}
//           alignItems={"center"}
//           bg={"rgba(255,255,255,0.05)"}
//           borderRadius={"12px"}
//         >
//           <Text
//             color="#FFF"
//             w={"fit-content"}
//             fontSize={{ base: "12px", md: "14px", "2xl": "16px" }}
//           >
//             {selectedOption}
//           </Text>
//           <Image src={downIcon} />
//         </Flex>
//       </MenuButton>
//       <MenuList
//         bg="#1D1E2D"
//         p="0px"
//         border="none"
//         // width={{ base: "90px", md: "160px" }}
//       >
//         {options.map((option: any) => (
//           <MenuItem
//             key={option}
//             bg="rgba(255,255,255,0.01)"
//             onClick={() => handleSelectOption(option)}
//           >
//             <Flex mr="120px" align="center" gap="4px">
//               <Text color="#FFF" lineHeight="40px">
//                 {option}
//               </Text>
//             </Flex>
//           </MenuItem>
//         ))}
//       </MenuList>
//     </Menu>
//   );
// };

// const AvgFilterCard = () => {
//   const [avgValue, setAvgValue] = useState(0);

//   return (
//     <Menu isLazy>
//       <MenuButton>
//         <Flex
//           // w={{ base: "90px", md: "160px" }}
//           p={{ base: "10px 12px", md: "10px 18px", "2xl": "10px 24px" }}
//           justifyContent={"space-between"}
//           alignItems={"center"}
//           bg={"rgba(255,255,255,0.05)"}
//           borderRadius={"12px"}
//         >
//           <Text
//             color="#FFF"
//             w={"fit-content"}
//             fontSize={{ base: "12px", md: "14px", "2xl": "16px" }}
//           >
//             Average Return
//           </Text>
//           <Image src={downIcon} />
//         </Flex>
//       </MenuButton>
//       <MenuList
//         bg="#1D1E2D"
//         p="12px"
//         border="none"
//         // width={{ base: "90px", md: "160px" }}
//       >
//         <Text
//           color={"#FFF"}
//           fontSize={{ base: "10px", md: "13px", xl: "16px" }}
//           fontWeight={"400"}
//           lineHeight={"40px"}
//           mb={"30px"}
//         >
//           Average Return
//         </Text>

//         <Slider
//           aria-label="slider-ex-6"
//           defaultValue={0}
//           colorScheme="green"
//           max={100}
//           min={-100}
//           value={avgValue}
//           focusThumbOnChange={false}
//           onChange={(val: any) => setAvgValue(val)}
//         >
//           <SliderMark
//             value={avgValue}
//             textAlign="center"
//             mt={"-20px"}
//             display={avgValue > 100 || avgValue < -100 ? "none" : "block"}
//           >
//             <div
//               style={{
//                 position: "relative",
//                 display: "inline-block",
//               }}
//             >
//               <div
//                 style={{
//                   position: "absolute",
//                   top: "-40px",
//                   // left: "60%",
//                   transform: "translateX(-50%)",
//                   textAlign: "center",
//                 }}
//               >
//                 <div
//                   style={{
//                     background: "#090A1A",
//                     borderRadius: "20px",
//                     color: "#FFF",
//                     padding: "4px 12px",
//                     fontSize: "12px",
//                     fontWeight: "700",
//                     letterSpacing: "0.048px",
//                     position: "relative",
//                   }}
//                 >
//                   {Number(avgValue || 0)?.toLocaleString()}
//                 </div>
//               </div>
//             </div>
//           </SliderMark>
//           <SliderTrack>
//             <SliderFilledTrack bg={"#00FFE0"} />
//           </SliderTrack>
//           <SliderThumb
//             display={"flex"}
//             w={"18px"}
//             h="18px"
//             p={"4px"}
//             alignItems={"flex-start"}
//             gap={"2.667px"}
//             borderRadius={"32px"}
//             border={"1.333px solid #000"}
//             bg={"#FFF"}
//             zIndex={0}
//           ></SliderThumb>
//         </Slider>
//         <Flex
//           justify={"space-between"}
//           w={"100%"}
//           color={"#FFF"}
//           mt={"-10px"}
//           mb={"10px"}
//           fontSize={{ base: "10px", md: "13px", xl: "16px" }}
//         >
//           <Text>-100</Text>
//           <Text>100</Text>
//         </Flex>
//         <Input
//           placeholder="0"
//           variant="unstyled"
//           color="#FFF"
//           fontSize={{ base: "10px", md: "13px", xl: "16px" }}
//           lineHeight={{ md: "40px" }}
//           _placeholder={{
//             color: "#FFF",
//           }}
//           display="flex"
//           h={"40px"}
//           min={-100}
//           max={100}
//           padding={{ base: "5px 12px", md: "7px 16px", xl: "10px 24px" }}
//           justifyContent="space-between"
//           alignItems="center"
//           borderRadius={{ base: "4px", md: "12px" }}
//           bg="rgba(255,255,255,0.05)"
//           value={avgValue}
//           type="number"
//           onChange={(e: any) => setAvgValue(e.target.value)}
//         />
//         <Flex mt={"10px"} gap={"5px"} align={"center"}>
//           <Button
//             display="flex"
//             variant={"unstyled"}
//             padding={{ base: "5px 12px", md: "7px 16px", xl: "10px 24px" }}
//             justifyContent="center"
//             alignItems="center"
//             gap="10px"
//             flex={{ lg: "1 0 0" }}
//             borderRadius="12px"
//             bgGradient="linear(to-r, #EE683D, #F38D3F)"
//             fontSize={{ base: "10px", md: "13px", xl: "16px" }}
//             lineHeight={{ md: "40px" }}
//             color="#FFF"
//             position="unset"
//             h={"40px"}
//             onClick={() => setAvgValue(-100)}
//           >
//             Min
//           </Button>
//           <Button
//             display="flex"
//             variant={"unstyled"}
//             padding={{ base: "5px 12px", md: "7px 16px", xl: "10px 24px" }}
//             justifyContent="center"
//             alignItems="center"
//             gap="10px"
//             flex={{ lg: "1 0 0" }}
//             borderRadius="12px"
//             bgGradient="linear(to-r, #EE683D, #F38D3F)"
//             fontSize={{ base: "10px", md: "13px", xl: "16px" }}
//             lineHeight={{ md: "40px" }}
//             color="#FFF"
//             position="unset"
//             h={"40px"}
//             onClick={() => setAvgValue(100)}
//           >
//             Max
//           </Button>
//         </Flex>
//       </MenuList>
//     </Menu>
//   );
// };
