import { Box, Divider, Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";
import homeBg from "../assets/imgs/homeBg.svg";
import Header from "../components/Header";
import { IconCircleSquare } from "@tabler/icons-react";

const WalletOverview = () => {
  return (
    <Box
      maxW="1680px"
      mx="auto"
      px="20px"
      pb={{ base: "85px", md: "20px" }}
      bgImage={`url(${homeBg})`}
      //   bgSize={"cover"}
      bgPosition={"center"}
      bgRepeat="no-repeat"
    >
      <Box>
        <Header />
      </Box>
      <Box pt={{ base: "16px", md: "100px" }}>
        <Flex
          align="flex-start"
          justify="center"
          flexDir="column"
          maxW="1250px"
          gap="26px"
          mx="auto"
        >
          <Flex
            align="center"
            fontSize={{ base: "24px", md: "32px", lg: "46px", "2xl": "60px" }}
            fontWeight="400"
            gap={{ base: "8px", lg: "12px", "2xl": "18px" }}
            fontFamily="Abril Fatface"
            justify={"center"}
            w={"100%"}
          >
            <Text color="#fff">Wallet</Text>
            <Text
              bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
              bgClip="text"
            >
              Overview
            </Text>
          </Flex>
          <Stack>
            <Text
              color="#fff"
              fontSize={{ base: "12px", md: "16px", lg: "20px", "2xl": "24px" }}
              fontWeight="400"
              fontFamily="Abril Fatface"
            >
              Estimated Balance
            </Text>
            <Text
              color="#fff"
              fontSize={{ base: "12px", md: "16px", lg: "20px", "2xl": "24px" }}
              fontWeight="400"
              fontFamily="Abril Fatface"
            >
              4000
            </Text>
          </Stack>
          <Divider />
          <Stack w={"100%"}>
            <Text
              color="#fff"
              fontSize={{ base: "12px", md: "16px", lg: "20px", "2xl": "24px" }}
              fontWeight="400"
              fontFamily="Abril Fatface"
            >
              My Assets
            </Text>
            <Flex
              justify={"space-between"}
              w={"100%"}
              align={"center"}
              mt={"20px"}
            >
              <Flex align={"center"} gap={"10px"}>
                <IconCircleSquare color="#FFF" />
                <Text
                  color="#fff"
                  fontSize={{
                    base: "12px",
                    md: "16px",
                    lg: "20px",
                    "2xl": "24px",
                  }}
                  fontWeight="400"
                  fontFamily="Abril Fatface"
                >
                  Spot
                </Text>
              </Flex>
              <Text
                color="#fff"
                fontSize={{
                  base: "12px",
                  md: "16px",
                  lg: "20px",
                  "2xl": "24px",
                }}
                fontWeight="400"
                fontFamily="Abril Fatface"
              >
                1000
              </Text>
            </Flex>
            <Flex
              justify={"space-between"}
              w={"100%"}
              align={"center"}
              mt={"10px"}
            >
              <Flex align={"center"} gap={"10px"}>
                <IconCircleSquare color="#FFF" />
                <Text
                  color="#fff"
                  fontSize={{
                    base: "12px",
                    md: "16px",
                    lg: "20px",
                    "2xl": "24px",
                  }}
                  fontWeight="400"
                  fontFamily="Abril Fatface"
                >
                  Funding
                </Text>
              </Flex>
              <Text
                color="#fff"
                fontSize={{
                  base: "12px",
                  md: "16px",
                  lg: "20px",
                  "2xl": "24px",
                }}
                fontWeight="400"
                fontFamily="Abril Fatface"
              >
                3000
              </Text>
            </Flex>
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
};

export default WalletOverview;
