import React, { useState } from "react";
import Header from "../components/Header";
import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import homeBg from "../assets/imgs/homeBg.svg";
import downIcon from "../assets/imgs/down_icon.svg";
import monkeyImg from "../assets/imgs/monkey_img.svg";
import Footer from "../components/Footer";
import LeaderboardTable from "../components/LeaderboardTable";
import closedTrades from "../db/Closed trades.json";

interface TradeData {
  rank: number;
  trader: {
    imageSrc: string;
    name: string;
  };
  followers: string;
  token: string;
  holdTime: number;
  return: string;
  reward: string;
}

const filterHighestReturnTrades = (trades: TradeData[]): TradeData[] => {
  const traderMap: { [key: string]: TradeData } = {};
  trades.forEach((trade) => {
    const traderName = trade.trader.name;
    if (
      !traderMap[traderName] ||
      parseFloat(traderMap[traderName].return) < parseFloat(trade.return)
    ) {
      traderMap[traderName] = trade;
    }
  });
  return Object.values(traderMap);
};

const tableData: TradeData[] = filterHighestReturnTrades(
  closedTrades?.Sheet1?.map((trade: any, index: number) => ({
    rank: index + 1,
    trader: {
      imageSrc: monkeyImg,
      name: trade?.Trader,
    },
    followers: trade?.followers || "123",
    token: trade?.Token,
    holdTime: trade["Hold Time (Days)"],
    return: trade?.Return,
    reward: trade?.Reward || "1234",
  })) || []
);

tableData.sort((a, b) => a.rank - b.rank);

const Leaderboard = () => {
  const [selectedOption, setSelectedOption] = useState("Annual");

  const handleSelectOption = (option: any) => {
    setSelectedOption(option);
  };

  return (
    <Box
      maxW="1680px"
      mx="auto"
      px="20px"
      pb={{ base: "85px", md: "20px" }}
      bgImage={`url(${homeBg})`}
      bgSize="cover"
      bgPosition={"center"}
      bgRepeat="no-repeat"
    >
      <Header />
      <Box pt={{ base: "16px", md: "100px" }}>
        <Flex
          align="center"
          justify="center"
          flexDir="column"
          maxW="1250px"
          gap="16px"
          mx="auto"
        >
          <Flex
            align="center"
            fontSize={{ base: "24px", md: "32px", lg: "46px", "2xl": "60px" }}
            fontWeight="400"
            gap={{ base: "8px", lg: "12px", "2xl": "18px" }}
            fontFamily="Abril Fatface"
          >
            <Text color="#fff">Leader</Text>
            <Text
              bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
              bgClip="text"
            >
              board
            </Text>
          </Flex>
          <Text
            fontSize={{ base: "12px", md: "18px", lg: "22px" }}
            fontWeight="400"
            color="#fff"
            textAlign="center"
          >
            Lorem ipsum dolor sit amet consectetur. Eget viverra arcu elementum
            tellus tortor facilisi nisl leo purus. Pulvinar vitae donec donec
            aenean ultrices vitae interdum laoreet non.
          </Text>
        </Flex>
      </Box>
      <Flex
        justify={"space-between"}
        align={"center"}
        maxW={{ lg: "1405px" }}
        mx={"auto"}
        w={"100%"}
        pt={{ base: "16px", md: "100px" }}
      >
        <Text
          color={"#FFF"}
          fontSize={{ base: "16px", md: "22px", lg: "30px" }}
          letterSpacing={"0.3px"}
          fontFamily={"Abril Fatface"}
        >
          Leader Board Stats
        </Text>
        <Menu isLazy>
          <MenuButton>
            <Flex
              w={{ base: "90px", md: "160px" }}
              p={{ base: "10px 12px", md: "10px 24px" }}
              justifyContent={"space-between"}
              alignItems={"center"}
              bg={"rgba(255,255,255,0.05)"}
              borderRadius={"12px"}
            >
              <Text
                color="#FFF"
                w={"fit-content"}
                // lineHeight="40px"
                fontSize={{ base: "12px", md: "16px" }}
              >
                {selectedOption}
              </Text>
              <Image src={downIcon} />
            </Flex>
          </MenuButton>
          <MenuList
            bg="#1D1E2D"
            p="0px"
            border="none"
            width={{ base: "50px", md: "160px" }}
          >
            <MenuItem
              bg="rgba(255,255,255,0.01)"
              onClick={() => handleSelectOption("Annual")}
            >
              <Flex mr="120px" align="center" gap="4px">
                <Text
                  color="#FFF"
                  lineHeight={{ md: "40px" }}
                  fontSize={{ base: "12px", md: "16px" }}
                >
                  Annual
                </Text>
              </Flex>
            </MenuItem>
            <MenuItem
              bg="rgba(255,255,255,0.01)"
              onClick={() => handleSelectOption("Monthly")}
            >
              <Flex mr="120px" align="center" gap="4px">
                <Text
                  color="#FFF"
                  lineHeight={{ md: "40px" }}
                  fontSize={{ base: "12px", md: "16px" }}
                >
                  Monthly
                </Text>
              </Flex>
            </MenuItem>
            <MenuItem
              bg="rgba(255,255,255,0.01)"
              onClick={() => handleSelectOption("Weekly")}
            >
              <Flex mr="120px" align="center" gap="4px">
                <Text
                  color="#FFF"
                  lineHeight={{ md: "40px" }}
                  fontSize={{ base: "12px", md: "16px" }}
                >
                  Weekly
                </Text>
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <LeaderboardTable data={tableData} rowsPerPage={20} />
      <Footer />
    </Box>
  );
};

export default Leaderboard;
