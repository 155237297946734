import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Retainers from "../components/Retainers";
import Exchange from "../components/Exchange";
import Stake from "../components/Stake";
import RoadMap from "../components/RoadMap";
import Footer from "../components/Footer";
import homeBg from "../assets/imgs/homeBg.svg";

function Home() {
  return (
    <Box
      maxW="1680px"
      mx="auto"
      px="20px"
      pb={{ base: "85px", md: "20px" }}
      bgImage={`url(${homeBg})`}
      bgSize="cover"
      bgPosition={"center"}
      bgRepeat="no-repeat"
    >
      <Header />
      <Hero />
      <Retainers />
      <Exchange />
      <Stake />
      <RoadMap />
      <Footer />
    </Box>
  );
}

export default Home;
