import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputRightAddon,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import homeBg from "../assets/imgs/homeBg.svg";

const Stakings = () => {
  const [selected, setSelected] = useState("1-month");

  const handleSelected = (option: any) => {
    setSelected(option);
  };

  return (
    <Box
      maxW="1680px"
      mx="auto"
      px="20px"
      pb={{ base: "85px", md: "20px" }}
      bgImage={`url(${homeBg})`}
      bgSize={"contain"}
      bgPosition={"center"}
      bgRepeat="no-repeat"
    >
      <Header />
      <Box pt={{ base: "16px", md: "100px" }}>
        <Flex
          align="center"
          justify="center"
          flexDir="column"
          maxW="1250px"
          gap="16px"
          mx="auto"
        >
          <Flex
            align="center"
            fontSize={{ base: "24px", md: "32px", lg: "46px", "2xl": "60px" }}
            fontWeight="400"
            gap={{ base: "8px", lg: "12px", "2xl": "18px" }}
            fontFamily="Abril Fatface"
          >
            <Text color="#fff">Stake</Text>
            <Text
              bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
              bgClip="text"
            >
              $CMPS
            </Text>
          </Flex>
          <Text
            fontSize={{ base: "12px", md: "18px", lg: "22px" }}
            fontWeight="400"
            color="#fff"
            textAlign="center"
          >
            Lorem ipsum dolor sit amet consectetur. Eget viverra arcu elementum
            tellus tortor facilisi nisl leo purus. Pulvinar vitae donec donec
            aenean ultrices vitae interdum laoreet non.
          </Text>
        </Flex>
      </Box>
      <Stack
        align={"center"}
        gap={{ base: "30px", md: "60px" }}
        mt={{ base: "24px", md: "80px" }}
      >
        <Text
          color={"#FFF"}
          fontSize={{ base: "18px", md: "30px" }}
          fontFamily={"Abril Fatface"}
        >
          Staking Rewards
        </Text>
        <Flex
          align={{ base: "flex-start", md: "center" }}
          gap={"25px"}
          w={"100%"}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Stack
            p={{ base: "16px", md: "24px" }}
            justify={"space-between"}
            align={"flex-start"}
            borderRadius={"10px"}
            bg={"rgba(255,255,255,0.05)"}
            h={{ lg: "538px" }}
            gap={{ base: "16px", md: "0px" }}
            w={{ base: "100%", md: "65%" }}
          >
            <Flex
              align={"flex-start"}
              gap={{ base: "6px", md: "20px" }}
              // flexWrap={"wrap"}
              w={{ base: "100%", sm: "75%", md: "100%", xl: "75%" }}
              flexDirection={{ base: "column", lg: "row" }}
            >
              <Flex w={"100%"} gap={{ base: "6px", md: "20px" }}>
                <TabCard
                  text="1-month"
                  onClick={handleSelected}
                  selected={selected}
                />
                <TabCard
                  text="3-month"
                  onClick={handleSelected}
                  selected={selected}
                />
              </Flex>
              <Flex w={"100%"} gap={{ base: "6px", md: "20px" }}>
                <TabCard
                  text="6-month"
                  onClick={handleSelected}
                  selected={selected}
                />
                <TabCard
                  text="12-month"
                  onClick={handleSelected}
                  selected={selected}
                />
              </Flex>
            </Flex>
            <Flex align={"flex-start"} gap={"20px"} w={"100%"}>
              <Stack
                align={"flex-start"}
                gap={{ base: "12px", md: "20px" }}
                flex={{ md: "1 0 0" }}
              >
                <Flex align={"flex-start"} gap={"16px"}>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "10px", md: "22px" }}
                    lineHeight={{ md: "40px" }}
                  >
                    Locked Period:
                  </Text>
                  <Text
                    color={"#FFF"}
                    fontSize={{ base: "12px", md: "22px" }}
                    lineHeight={{ md: "40px" }}
                  >
                    {selected}
                  </Text>
                </Flex>
                <Flex align={"flex-start"} gap={"16px"}>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "10px", md: "22px" }}
                    lineHeight={{ md: "40px" }}
                  >
                    Number of Token Staked:
                  </Text>
                  <Text
                    color={"#FFF"}
                    fontSize={{ base: "12px", md: "22px" }}
                    lineHeight={{ md: "40px" }}
                  >
                    20
                  </Text>
                </Flex>
                <Flex align={"flex-start"} gap={"16px"}>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "10px", md: "22px" }}
                    lineHeight={{ md: "40px" }}
                  >
                    Token Earned
                  </Text>
                  <Text
                    color={"#FFF"}
                    fontSize={{ base: "12px", md: "22px" }}
                    lineHeight={{ md: "40px" }}
                  >
                    10
                  </Text>
                </Flex>
                <Flex align={"flex-start"} gap={"16px"}>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "12px", md: "22px" }}
                    lineHeight={{ md: "40px" }}
                  >
                    Time Left
                  </Text>
                  <Text
                    color={"#FFF"}
                    fontSize={{ base: "12px", md: "22px" }}
                    lineHeight={{ md: "40px" }}
                  >
                    1h 30mins
                  </Text>
                </Flex>
              </Stack>
              <Stack p={"24px"} justify={"center"} align={"center"}>
                <Text
                  color={"#7F7F7F"}
                  fontSize={{ base: "30px", md: "60px" }}
                  lineHeight={"normal"}
                  fontFamily={"Abril Fatface"}
                  background="var(--Gradient, linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%))"
                  backgroundClip={"text"}
                >
                  {selected === "1-month"
                    ? "5%"
                    : selected === "3-month"
                    ? "25%"
                    : selected === "6-month"
                    ? "75%"
                    : "150%"}
                </Text>
                <Text
                  color={"#D9D9D9"}
                  fontSize={{ base: "12px", md: "22px" }}
                  lineHeight={"40px"}
                  textTransform={"uppercase"}
                >
                  Reward
                </Text>
              </Stack>
            </Flex>
            <Stack
              align={"flex-start"}
              gap={{ base: "16px", md: "30px" }}
              w={"100%"}
            >
              <Flex
                align={"flex-start"}
                gap={{ base: "12px", md: "30px" }}
                alignSelf={"stretch"}
                w={"100%"}
              >
                <InputGroup
                  p={{ base: "0px 24px", md: "10px 24px" }}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  flex={"1 0 0"}
                  borderRadius={"12px"}
                  bg={"rgba(255,255,255,0.05)"}
                  color={"#FFF"}
                  fontSize={{ base: "14px", md: "20px" }}
                  lineHeight={"40px"}
                  _placeholder={{ color: "#FFF" }}
                >
                  <Input variant={"unstyled"} value={"0"} />
                  <InputRightAddon
                    bg={"none"}
                    border={"none"}
                    cursor={"pointer"}
                  >
                    <Text
                      color={"#FFF"}
                      lineHeight={"40px"}
                      fontSize={{ base: "12px", md: "16px" }}
                    >
                      MAX
                    </Text>
                  </InputRightAddon>
                </InputGroup>
                <Button
                  variant={"unstyled"}
                  w={{ base: "96px", md: "150px" }}
                  p={"10px 24px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"10px"}
                  borderRadius={{ base: "6px", md: "12px" }}
                  bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
                  color={"#FFF"}
                  fontSize={{ base: "12px", md: "20px" }}
                  lineHeight={{ md: "40px" }}
                  h={"100%"}
                  position="unset"
                >
                  Approve
                </Button>
              </Flex>
              <Button
                variant={"unstyled"}
                w={"100%"}
                p={"10px 24px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={{ base: "6px", md: "12px" }}
                bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
                color={"#FFF"}
                fontSize={{ base: "12px", md: "20px" }}
                lineHeight={{ md: "40px" }}
                h={"100%"}
                position="unset"
              >
                Withdraw All
              </Button>
            </Stack>
          </Stack>
          <Stack
            h={{ lg: "538px" }}
            align={"center"}
            gap={"24px"}
            justify={"space-between"}
            flex={"1 0 0"}
            w={"100%"}
          >
            <RewardCard
              title="APY"
              value={
                selected === "1-month"
                  ? "60%"
                  : selected === "3-month"
                  ? "100%"
                  : selected === "6-month"
                  ? "150%"
                  : "200%"
              }
            />
            <RewardCard title="Total value locked" value="$9,574,20.84" />
            <RewardCard title="Number of stakes" value="222" />
          </Stack>
        </Flex>
      </Stack>
      <Stack align={"center"} gap={"60px"} mt={{ base: "24px", md: "80px" }}>
        <Text color={"#FFF"} fontSize={{ base: "18px", md: "30px" }}>
          Staking Benefits
        </Text>
        <Flex
          justify={"space-between"}
          gap={{ base: "30px", md: "60px" }}
          align={"stretch"}
          flexDirection={{ base: "column", md: "row" }}
          //   alignSelf={"stretch"}
        >
          <BenefitCard
            tokens="1,000"
            listText={[
              "Access to private telegram/discord for the project. These chats will be used to vote on how the treasury funds should be spent, as well as just a place for the community to discuss and receive updates",
              "10% discount on monthly subscription fees",
              "Max number of open trades 10 instead of 5",
            ]}
          />
          <BenefitCard
            tokens="10,000"
            listText={[
              "All previous features plus ",
              "Auto buy and sell calls from followed traders",
              "20% discount on monthly subscription fees",
            ]}
          />
          <BenefitCard
            tokens="50,000"
            listText={[
              "All previous features plus",
              "Max hold time per trade 12 months instead of 6",
              "Max number of open trades 20 instead of 5",
              "50% discount on monthly subscription fees",
            ]}
          />
        </Flex>
      </Stack>
      <Footer />
    </Box>
  );
};

export default Stakings;

const TabCard = ({ text, onClick, selected }: any) => {
  return (
    <Center
      // w={"150px"}
      p={{ base: "5px 12px", md: "7px 16px", xl: "10px 24px" }}
      gap={"10px"}
      borderRadius={{ base: "6px", md: "12px" }}
      flex={"1 0 0"}
      bg={"rgba(255,255,255,0.05)"}
      bgGradient={selected === text ? "linear(to-r, #EE683D, #F38D3F)" : ""}
      color={"#FFF"}
      fontSize={{ base: "12px", md: "16px", xl: "20px" }}
      fontWeight={{ base: "400", md: "500" }}
      lineHeight={{ md: "40px" }}
      cursor={"pointer"}
      onClick={() => onClick(text)}
    >
      {text}
    </Center>
  );
};

const RewardCard = ({ title, value }: any) => {
  return (
    <Stack
      p={{ base: "12px", md: "24px" }}
      align={{ base: "center", md: "flex-start" }}
      gap={{ md: "20px" }}
      borderRadius={{ base: "6px", md: "10px" }}
      bg={"rgba(255,255,255,0.05)"}
      w={"100%"}
    >
      <Text
        color={"#D9D9D9"}
        fontSize={{ base: "12px", md: "22px" }}
        lineHeight={{ md: "40px" }}
        textTransform={"uppercase"}
      >
        {title}
      </Text>
      <Text
        background="var(--Gradient, linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%))"
        backgroundClip={"text"}
        fontSize={{ base: "20px", md: "40px" }}
        fontFamily={"Abril Fatface"}
      >
        {value}
      </Text>
    </Stack>
  );
};

const BenefitCard = ({ tokens, listText }: any) => {
  return (
    <Stack
      p={{ base: "12px", md: "24px" }}
      //   align={"center"}
      gap={{ base: "10px", md: "20px" }}
      flex={{ md: "1 0 0" }}
      borderRadius={{ base: "6px", md: "10px" }}
      bg={"rgba(255,255,255,0.05)"}
    >
      <Text
        // display={"flex"}
        // align={"center"}
        color={"#FFF"}
        fontSize={{ base: "12px", md: "22px" }}
        lineHeight={{ md: "40px" }}
        gap={"2px"}
        w={"100%"}
      >
        If user has more than{" "}
        <Text
          bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
          bgClip="text"
          display={"inline-block"}
        >
          {tokens}
        </Text>{" "}
        tokens staked:
      </Text>
      <Center>
        <Divider
          w={"51px"}
          bgGradient="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
        />
      </Center>
      <UnorderedList
        color={"#FFF"}
        fontSize={{ base: "10px", md: "18px" }}
        lineHeight={{ md: "40px" }}
      >
        {listText?.map((text: any, index: any) => (
          <ListItem key={index}>{text}</ListItem>
        ))}
      </UnorderedList>
    </Stack>
  );
};
