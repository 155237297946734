import { Flex } from "@chakra-ui/react";
import React from "react";
import RetainerCard from "./RetainerCard";

function Retainers() {
  return (
    <Flex
      mt={{ base: "48px", md: "150px" }}
      gap={{ base: "40px", md: "30px" }}
      maxW={"1405px"}
      mx="auto"
      w={"100%"}
      overflowX={"scroll"}
      overflowY={"unset"}
      sx={{
        "&::-webkit-scrollbar": {
          height: "4px",
          width: "0px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "gray.400",
          borderRadius: "8px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "gray.600",
        },
      }}
    >
      <RetainerCard title="Annual" />
      <RetainerCard title="Monthly" />
      <RetainerCard title="Weekly" />
    </Flex>
  );
}

export default Retainers;
