import React, { useState } from "react";
import homeBg from "../assets/imgs/homeBg.svg";
import {
  Box,
  Button,
  Center,
  Flex,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../utils/auth.api";

const Login = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const userLogin = useLogin();

  const [payload, setPayload] = useState({
    email: "",
    password: "",
  });

  const handleLogin = (e: any) => {
    e.preventDefault();
    if (payload.email === "" || payload.password === "") {
      toast({
        title: "Please fill all fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    userLogin
      ?.mutateAsync(payload)
      .then((result) => {
        toast({
          title: "Login Successful",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/");
      })
      .catch((err) => {
        toast({
          title: err.message || "Something went wrong!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Box
      maxW="1680px"
      mx="auto"
      px="20px"
      pb={{ base: "85px", md: "20px" }}
      bgImage={`url(${homeBg})`}
      bgSize="cover"
      bgPosition={"center"}
      bgRepeat="no-repeat"
      h={"100vh"}
    >
      <Center maxW={"650px"} mx={"auto"} h={"100%"}>
        <form onSubmit={handleLogin}>
          <Flex
            p={{ base: "12px", md: "24px" }}
            gap={{ md: "12px" }}
            align={"center"}
            flex={"1 0 0"}
            borderRadius={"10px"}
            bg={"rgba(255,255,255,0.05)"}
            w={"100%"}
            flexDirection={"column"}
            // cursor={onClick ? "pointer" : ""}
            // onClick={onClick ? onClick : ""}
          >
            <Text
              color={"#FFF"}
              fontSize={{ base: "12px", md: "18px", xl: "22px" }}
              lineHeight={{ md: "40px" }}
              textTransform={"uppercase"}
              bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
              bgClip="text"
              mb={"20px"}
            >
              Welcome to Compass. Please Login
            </Text>

            <Center w={"100%"}>
              <FormLabel w={"450px"}>
                <Text
                  color={"#FFF"}
                  fontSize={{ base: "11px", md: "16px", xl: "18px" }}
                  lineHeight={{ md: "18px" }}
                  mb={"8px"}
                >
                  Email
                </Text>

                <Input
                  variant={"unstyled"}
                  p={{ base: "0px 24px", md: "10px 24px" }}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  flex={"1 0 0"}
                  borderRadius={"12px"}
                  bg={"rgba(255,255,255,0.05)"}
                  color={"#FFF"}
                  fontSize={{ base: "11px", md: "16px", xl: "18px" }}
                  lineHeight={{ md: "18px" }}
                  placeholder="JohnDoe@gmail.com"
                  _placeholder={{ color: "#FFF", opacity: 0.5 }}
                  type="email"
                  value={payload.email}
                  onChange={(e) =>
                    setPayload({ ...payload, email: e.target.value })
                  }
                />
              </FormLabel>
            </Center>
            <Center>
              <FormLabel w={"450px"}>
                <Text
                  color={"#FFF"}
                  fontSize={{ base: "11px", md: "16px", xl: "18px" }}
                  lineHeight={{ md: "18px" }}
                  mb={"8px"}
                >
                  Password
                </Text>

                <Input
                  variant={"unstyled"}
                  p={{ base: "0px 24px", md: "10px 24px" }}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  flex={"1 0 0"}
                  borderRadius={"12px"}
                  bg={"rgba(255,255,255,0.05)"}
                  color={"#FFF"}
                  fontSize={{ base: "11px", md: "16px", xl: "18px" }}
                  lineHeight={{ md: "18px" }}
                  placeholder="Password"
                  _placeholder={{ color: "#FFF", opacity: 0.5 }}
                  type="password"
                  value={payload.password}
                  onChange={(e) =>
                    setPayload({ ...payload, password: e.target.value })
                  }
                />
              </FormLabel>
            </Center>
            <Flex justify={"flex-end"} w={"70%"}>
              <Text
                cursor={"pointer"}
                onClick={() => navigate(`/verifyEmail?pass=${true}`)}
                // bgGradient="linear(to-tr, #EE683D, #F38D3F)"
                // bgClip="text"
                color={"#FFF"}
              >
                Forgot Password.
              </Text>
            </Flex>

            <Center>
              <Button
                // maxW={"160px"}
                variant={"unstyled"}
                display="flex"
                p="10px 24px"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                flex={{ lg: "1 0 0" }}
                borderRadius="12px"
                border="1px solid #EE683D"
                fontSize={{ base: "12px", lg: "20px" }}
                lineHeight="40px"
                bgGradient="linear(to-tr, #EE683D, #F38D3F)"
                bgClip="text"
                position="unset"
                w={"450px"}
                mt={"20px"}
                type="submit"
                isDisabled={userLogin?.isPending}
                isLoading={userLogin?.isPending}
              >
                Login
              </Button>
            </Center>
            <Text
              color={"#FFF"}
              fontSize={{ base: "10px", md: "12px", xl: "16px" }}
              lineHeight={{ md: "18px" }}
              display={"flex"}
              gap={"2px"}
            >
              Don't have an account.{" "}
              <Text
                cursor={"pointer"}
                onClick={() => navigate("/signup")}
                bgGradient="linear(to-tr, #EE683D, #F38D3F)"
                bgClip="text"
              >
                Signup.
              </Text>
            </Text>
          </Flex>
        </form>
      </Center>
    </Box>
  );
};

export default Login;
