import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import homeBg from "../assets/imgs/homeBg.svg";
import Header from "./Header";
import profileImg from "../assets/imgs/profile_pic.svg";
import { IconArrowLeft } from "@tabler/icons-react";
import lockedBg from "../assets/imgs/locked_bg.svg";
import redCircle from "../assets/imgs/red_circle.svg";
import lock from "../assets/imgs/lock.svg";
import TradeHistory from "./TradeHistoryTable";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

const tableData = Array.from({ length: 200 }, (_, index) => ({
  token: "SOL",
  dateBought: "12/4/17",
  dateSold: "12/4/17",
  holdTime: "31 days",
  priceBought: "0.021",
  priceSold: "0.042",
  return: "+100%",
  Conviction: redCircle,
  //   mostUsed: tokenImg,
}));

const Profile = () => {
  const navigate = useNavigate();

  return (
    <Box
      maxW="1680px"
      mx="auto"
      px="20px"
      pb={{ base: "85px", md: "20px" }}
      bgImage={`url(${homeBg})`}
      bgSize={"cover"}
      bgPosition={"center"}
      bgRepeat="no-repeat"
    >
      <Box display={{ base: "none", md: "block" }}>
        <Header />
      </Box>
      <Box pt={{ base: "16px", md: "100px" }}>
        <Flex
          align="center"
          justify="center"
          flexDir="column"
          maxW="1250px"
          gap="26px"
          mx="auto"
        >
          <Center
            gap={"6px"}
            fontSize={"20px"}
            color={"#FFF"}
            display={{ base: "none", md: "flex" }}
          >
            <Text
              textDecorationLine={"underline"}
              bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
              bgClip="text"
              cursor={"pointer"}
              onClick={() => navigate("/filters")}
            >
              Filters
            </Text>
            <Text>/</Text>
            <Text>Trader's Profile</Text>
          </Center>
          <Flex
            display={{ base: "flex", md: "none" }}
            gap={"6px"}
            fontSize={"14x"}
            color={"#FFF"}
            align={"center"}
            justify={"flex-start"}
            w={"100%"}
          >
            <IconArrowLeft
              cursor={"pointer"}
              onClick={() => navigate("/filters")}
            />
            <Text w={"100%"} textAlign={"center"}>
              Trader Profile
            </Text>
          </Flex>
          <Flex
            align="center"
            fontSize={{ base: "24px", md: "32px", lg: "46px", "2xl": "60px" }}
            fontWeight="400"
            gap={{ base: "8px", lg: "12px", "2xl": "18px" }}
            fontFamily="Abril Fatface"
          >
            <Text color="#fff">Gladys</Text>
            <Text
              bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
              bgClip="text"
            >
              Profile
            </Text>
          </Flex>
          <Text
            fontSize={{ base: "12px", md: "18px", lg: "22px" }}
            fontWeight="400"
            color="#fff"
            textAlign="center"
          >
            Lorem ipsum dolor sit amet consectetur. Eget viverra arcu elementum
            tellus tortor facilisi nisl leo purus. Pulvinar vitae donec donec
            aenean ultrices vitae interdum laoreet non.
          </Text>
        </Flex>
      </Box>
      <Flex
        align={"flex-start"}
        gap={{ base: "12px", md: "30px", xl: "60px" }}
        maxW="1580px"
        mt={{ base: "12px", md: "30px", xl: "60px" }}
        mx="auto"
        w={"100%"}
        mb={{ base: "12px", lg: "0px" }}
      >
        <Image
          src={profileImg}
          alt="profileImg"
          h={{ base: "188px", md: "280px", lg: "460px" }}
        />
        <Stack
          align={"flex-start"}
          gap={{ base: "12px", md: "30px", xl: "60px" }}
          h={{ base: "188px", md: "270px", lg: "460px" }}
        >
          <Stack
            p={{ base: "12px", md: "24px" }}
            justify={"center"}
            align={"flex-start"}
            gap={{ base: "12px", md: "20px" }}
            borderRadius={{ base: "6px", md: "10px" }}
            bg={"rgba(255,255,255,00.05)"}
            flex={{ base: "1 0 0", md: "none" }}
          >
            <Stack
              justify={"center"}
              align={"flex-start"}
              gap={"12px"}
              alignSelf={"stretch"}
            >
              <Box>
                <Text
                  fontSize={{ base: "12px", md: "22px" }}
                  textTransform={"uppercase"}
                  color={"#FFF"}
                  lineHeight={{ md: "40px" }}
                >
                  Name
                </Text>
                <Text
                  fontFamily={"Abril Fatface"}
                  fontSize={{ base: "24px", md: "40px" }}
                  bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
                  bgClip="text"
                >
                  Gladys
                </Text>
              </Box>
              <Text
                color={"#FFF"}
                fontSize={{ base: "10px", md: "22px" }}
                lineHeight={{ md: "40px" }}
              >
                Lorem ipsum dolor sit amet consectetur. Et egestas pellentesque
                amet amet nunc. Nulla mauris dolor sed vulputate pretium.
              </Text>
            </Stack>
            <Flex
              align={"flex-start"}
              gap={{ md: "30px", xl: "60px" }}
              w={"100%"}
              display={{ base: "none", lg: "flex" }}
            >
              <ProfileCard text="Subscription Price" text2="100 $CMPS/Mo" />
              <ProfileCard text="Following" text2="21" />
              <ProfileCard text="Followers" text2="112" />
            </Flex>
          </Stack>
        </Stack>
      </Flex>
      <Stack
        align={"center"}
        gap={"12px"}
        alignSelf={"stretch"}
        w={"100%"}
        display={{ base: "flex", lg: "none" }}
      >
        <ProfileCard text="Subscription Price" text2="100 $CMPS/Mo" />
        <ProfileCard text="Following" text2="21" />
        <ProfileCard text="Followers" text2="112" />
      </Stack>
      <Flex
        maxW={"1580px"}
        mx={"auto"}
        p={{ base: "12px", md: "24px" }}
        justify={"space-between"}
        align={"center"}
        borderRadius={"10px"}
        bg={"rgba(255,255,255,0.05)"}
        w={"100%"}
        mt={{ base: "12px", md: "30px", xl: "60px" }}
        flexWrap={"wrap"}
      >
        <TextStack text="completed trade" text2="12" />
        <TextStack text="best trade" text2="-" />
        <TextStack text="worst trade" text2="-" />
        <TextStack text="average return" text2="-" />
        <TextStack text="average hold time" text2="-" />
      </Flex>
      <Flex
        maxW={"1580px"}
        mx={"auto"}
        p={{ base: "12px", md: "24px" }}
        mt={{ base: "12px", md: "30px", xl: "60px" }}
        justify={"space-between"}
        align={"center"}
        borderRadius={"10px"}
        bg={"rgba(255,255,255,0.05)"}
        w={"100%"}
        flexWrap={"wrap"}
      >
        <TextStack text="Winning trades" text2="-" />
        <TextStack text="losing trades" text2="-" />
        <TextStack text="open trades" text2="3" color="#00CD08" />
        <TextStack text="average open trades" text2="-9%" color="#F00" />
        <TextStack text="member since" text2="Feb,2024" />
      </Flex>
      <Stack
        gap={{ base: "12px", md: "30px", xl: "60px" }}
        mt={{ base: "12px", md: "30px", xl: "60px" }}
        align={"flex-start"}
      >
        <Text
          color={"#FFF"}
          fontFamily={"Abril Fatface"}
          fontSize={{ base: "18px", md: "30px" }}
        >
          Open Trades
        </Text>
        <Box
          w={"100%"}
          display={"flex"}
          h={{ base: "120px", md: "200px" }}
          p={{ base: "12px", md: "24px" }}
          flexDirection={"column"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderRadius={{ base: "12px", md: "20px" }}
          bg={{
            base: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${lockedBg}) lightgray 50% / cover no-repeat`,
            md: `url(${lockedBg}) lightgray 50% / cover no-repeat`,
          }}
          bgSize="cover"
          bgPosition="center"
          bgRepeat="no-repeat"
        >
          <Image src={lock} alt="lock" w={{ base: "30px", md: "60px" }} />
          <Button
            display={"flex"}
            w={{ base: "90px", md: "150px" }}
            p={{ base: "5px 12px", md: "10px 24px" }}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"10px"}
            borderRadius={{ base: "6px", md: "12px" }}
            bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
            color={"#FFF"}
            fontSize={{ base: "12px", md: "20px" }}
            lineHeight={{ md: "40px" }}
            position="unset"
          >
            Unlock
          </Button>
        </Box>
      </Stack>
      <Stack
        gap={{ base: "12px", md: "30px", xl: "60px" }}
        mt={{ base: "12px", md: "30px", xl: "60px" }}
        align={"flex-start"}
        w={"100%"}
      >
        <Text
          color={"#FFF"}
          fontFamily={"Abril Fatface"}
          fontSize={{ base: "18px", md: "30px" }}
        >
          Trade History
        </Text>
        <Box w={"100%"}>
          <TradeHistory data={tableData} rowsPerPage={10} />
        </Box>
      </Stack>
      <Footer />
    </Box>
  );
};

export default Profile;

const ProfileCard = ({ text, text2 }: any) => {
  return (
    <Flex
      p={{ base: "12px", md: "24px" }}
      gap={{ md: "20px" }}
      align={"center"}
      flex={"1 0 0"}
      borderRadius={"10px"}
      bg={"rgba(255,255,255,0.05)"}
      w={"100%"}
    >
      <Stack
        justify={"center"}
        align={{ base: "center", md: "flex-start" }}
        gap={"-4px"}
        w={"100%"}
      >
        <Text
          color={"#FFF"}
          fontSize={{ base: "12px", md: "18px", xl: "22px" }}
          lineHeight={{ md: "40px" }}
          textTransform={"uppercase"}
        >
          {text}
        </Text>
        <Text
          bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
          bgClip="text"
          fontFamily={"Abril Fatface"}
          fontSize={{ base: "20px", md: "20px", lg: "32px", "2xl": "40px" }}
        >
          {text2}
        </Text>
      </Stack>
    </Flex>
  );
};

const TextStack = ({ text, text2, color }: any) => {
  return (
    <Stack
      justify={"center"}
      align={{ base: "center", md: "flex-start" }}
      gap={"-4px"}
    >
      <Text
        color={"#FFF"}
        fontSize={{ base: "12px", md: "22px" }}
        lineHeight={{ md: "40px" }}
        textTransform={"uppercase"}
      >
        {text}
      </Text>
      <Text
        color={color || ""}
        bg={
          color
            ? ""
            : "var(--Gradient, linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%))"
        }
        bgClip={color ? "" : "text"}
        fontFamily={"Abril Fatface"}
        fontSize={{ base: "20px", md: "40px" }}
      >
        {text2}
      </Text>
    </Stack>
  );
};
