import React, { useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

const OpenTradeTable = ({ data, rowsPerPage }: any) => {
  const [currentPage] = useState(1);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);
  console.log(currentRows);

  return (
    <Box w={"100%"} position={"relative"}>
      <TableContainer
        color={"#FFF"}
        mx={"auto"}
        w={"100%"}
        px={{ base: "16px", md: "40px" }}
        pt={{ base: "16px", md: "60px" }}
        sx={{
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            display: "none",
          },
          "&::-webkit-scrollbar-track": {
            display: "none",
          },
          "@media screen and (max-width: 62em)": {
            // for screens from base to lg
            "&::after": {
              content: "''",
              position: "absolute",
              top: "0",
              right: "0",
              width: "12px", // Adjust width as needed
              height: "100%",
              background:
                "linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,1))",
            },
          },
        }}
      >
        <Table variant="unstyled" overflowX={"hidden"}>
          <Thead>
            <Tr>
              <Th
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
                pr={"90px"}
              >
                Token
              </Th>
              <Th
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
              >
                Date Bought
              </Th>

              <Th
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
              >
                Hold Time
              </Th>
              <Th
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
              >
                Price Bought
              </Th>
              <Th
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
              >
                Live Price
              </Th>
              <Th
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
              >
                Return
              </Th>

              <Th
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
              >
                Conviction
              </Th>
              <Th
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
              >
                Sell Trade
              </Th>
            </Tr>
          </Thead>
          <Box mt={{ base: "0px", md: "60px" }}></Box>
          <Tbody>
            {currentRows.map((rowData: any, index: any) => (
              <Tr
                key={index}
                bg={index % 2 !== 0 ? "rgba(255,255,255,0.05)" : "none"}
                borderRadius={"12px"}
              >
                <Td>
                  <Flex
                    align={{ base: "center", md: "flex-start" }}
                    gap={"11px"}
                    my={{
                      base: index % 2 === 0 ? "8px" : "0px",
                      md: index % 2 === 0 ? "32px" : "0px",
                    }}
                  >
                    <Text
                      color={"#7F7F7F"}
                      fontSize={{ base: "12px", md: "18px" }}
                      fontWeight={"400"}
                      lineHeight={"40px"}
                    >
                      {rowData.token}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "12px", md: "18px" }}
                    fontWeight={"400"}
                    lineHeight={"40px"}
                    textAlign={"center"}
                    my={{
                      base: index % 2 === 0 ? "8px" : "0px",
                      md: index % 2 === 0 ? "32px" : "0px",
                    }}
                  >
                    {" "}
                    {rowData.dateBought}
                  </Text>
                </Td>

                <Td>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "12px", md: "18px" }}
                    fontWeight={"400"}
                    lineHeight={"40px"}
                    textAlign={"center"}
                    my={{
                      base: index % 2 === 0 ? "8px" : "0px",
                      md: index % 2 === 0 ? "32px" : "0px",
                    }}
                  >
                    {rowData.holdTime}
                  </Text>
                </Td>
                <Td>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "12px", md: "18px" }}
                    fontWeight={"400"}
                    textAlign={"center"}
                    lineHeight={"40px"}
                    my={{
                      base: index % 2 === 0 ? "8px" : "0px",
                      md: index % 2 === 0 ? "32px" : "0px",
                    }}
                  >
                    {rowData.priceBought}
                  </Text>
                </Td>
                <Td>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "12px", md: "18px" }}
                    fontWeight={"400"}
                    lineHeight={"40px"}
                    textAlign={"center"}
                    my={{
                      base: index % 2 === 0 ? "8px" : "0px",
                      md: index % 2 === 0 ? "32px" : "0px",
                    }}
                  >
                    {rowData.livePrice}
                  </Text>
                </Td>
                <Td>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "12px", md: "18px" }}
                    fontWeight={"400"}
                    lineHeight={"40px"}
                    textAlign={"center"}
                    my={{
                      base: index % 2 === 0 ? "8px" : "0px",
                      md: index % 2 === 0 ? "32px" : "0px",
                    }}
                  >
                    {rowData.return}
                  </Text>
                </Td>

                <Td>
                  <Center>
                    <Image src={rowData?.Conviction} alt="chain" />
                  </Center>
                </Td>
                <Td>
                  <Button
                    display={"flex"}
                    p={"24px"}
                    flexDir={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={"20px"}
                    //   flex={"1 0 0"}
                    alignSelf={"stretch"}
                    borderRadius={"10px"}
                    bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
                    color={"#FFF"}
                    position="unset"
                  >
                    {rowData?.sellTrade}
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OpenTradeTable;
