import React, { useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import downIcon from "../assets/imgs/down_icon.svg";
import pos1 from "../assets/imgs/position1.svg";
import pos2 from "../assets/imgs/position2.svg";
import btc from "../assets/imgs/btc.png";
import { useNavigate } from "react-router-dom";
import { IconTrendingUp } from "@tabler/icons-react";

const BuyTable = ({ data, rowsPerPage }: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);
  console.log(currentRows);
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const navigate = useNavigate();

  return (
    <Box position={"relative"}>
      <TableContainer
        color={"#FFF"}
        mx={"auto"}
        w={"100%"}
        px={{ base: "16px", md: "40px" }}
        pt={{ base: "16px", md: "60px" }}
        sx={{
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            display: "none",
          },
          "&::-webkit-scrollbar-track": {
            display: "none",
          },
          "@media screen and (max-width: 62em)": {
            // for screens from base to lg
            "&::after": {
              content: "''",
              position: "absolute",
              top: "0",
              right: "0",
              width: "12px", // Adjust width as needed
              height: "100%",
              background:
                "linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,1))",
            },
          },
        }}
      >
        <Table variant="unstyled">
          <Thead>
            <Tr>
              <Th
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "20px" }}
              >
                No
              </Th>
              <Th
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "20px" }}
              >
                Name
              </Th>
              <Th
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "20px" }}
                textAlign={"center"}
              >
                Price
              </Th>
              <Th
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "20px" }}
                textAlign={"center"}
              >
                MarketCap
              </Th>
              <Th
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "20px" }}
                textAlign={"center"}
              >
                Chain
              </Th>
              <Th
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "20px" }}
                textAlign={"center"}
              >
                Market STATS
              </Th>
              <Th
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "20px" }}
                textAlign={"center"}
              >
                Trade
              </Th>
            </Tr>
          </Thead>
          <Box mt={{ base: "0px", md: "60px" }}></Box>
          <Tbody>
            {currentRows.map((rowData: any, index: any) => (
              <Tr
                key={index}
                bg={index % 2 !== 0 ? "rgba(255,255,255,0.05)" : "none"}
                borderRadius={"12px"}
              >
                <Td>
                  <Box
                    display={"flex"}
                    p={"0px 5px"}
                    flexDirection={"column"}
                    borderRadius={"12px"}
                    border={"2px solid rgba(255,255,255,0.20)"}
                    color={"#FFF"}
                    textAlign={"center"}
                    fontFamily={"Poppins"}
                    fontSize={{ base: "10px", md: "18px" }}
                    fontWeight={"600"}
                    lineHeight={"30px"}
                    w={{ base: "30px", md: "40px" }}
                    my={{
                      base: index % 2 === 0 ? "8px" : "0px",
                      md: index % 2 === 0 ? "32px" : "0px",
                    }}
                  >
                    {index + 1}
                  </Box>
                </Td>
                <Td>
                  <Flex
                    align={{ base: "center", md: "flex-start" }}
                    gap={"11px"}
                    my={{
                      base: index % 2 === 0 ? "4px" : "0px",
                      md: index % 2 === 0 ? "16px" : "0px",
                    }}
                    justify={"center"}
                  >
                    <Image
                      src={btc}
                      alt="trader"
                      w={{ base: "25px", md: "40px" }}
                    />
                    <Text
                      color={"#7F7F7F"}
                      fontSize={{ base: "12px", md: "18px" }}
                      fontWeight={"400"}
                      lineHeight={"40px"}
                      textAlign={"center"}
                    >
                      {rowData.name}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "12px", md: "18px" }}
                    fontWeight={"400"}
                    lineHeight={"40px"}
                    textAlign={"center"}
                    my={{
                      base: index % 2 === 0 ? "4px" : "0px",
                      md: index % 2 === 0 ? "16px" : "0px",
                    }}
                  >
                    {rowData?.price}
                  </Text>
                </Td>
                <Td>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "12px", md: "18px" }}
                    fontWeight={"400"}
                    lineHeight={"40px"}
                    textAlign={"center"}
                    my={{
                      base: index % 2 === 0 ? "4px" : "0px",
                      md: index % 2 === 0 ? "16px" : "0px",
                    }}
                  >
                    {rowData.marketCap}
                  </Text>
                </Td>
                <Td>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "12px", md: "18px" }}
                    fontWeight={"400"}
                    lineHeight={"40px"}
                    textAlign={"center"}
                    my={{
                      base: index % 2 === 0 ? "4px" : "0px",
                      md: index % 2 === 0 ? "16px" : "0px",
                    }}
                  >
                    {rowData.chain}
                  </Text>
                </Td>
                <Td>
                  <Center>
                    <IconTrendingUp color="green" size={70} />
                  </Center>
                </Td>

                <Td>
                  <Button
                    variant={"unstyled"}
                    display={"flex"}
                    p={{
                      base: "10px 12px",
                      md: "10px 18px",
                      "2xl": "10px 24px",
                    }}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={"10px"}
                    borderRadius={"12px"}
                    bg="var(--Gradient, linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%))"
                    color={"#FFF"}
                    fontSize={{ base: "12px", md: "16px", "2xl": "20px" }}
                    lineHeight={{ md: "40px" }}
                    w={{ base: "100%", sm: "100%", md: "100%" }}
                    position="unset"
                    onClick={() => navigate("/buy/trade")}
                    // w={{ base: "200%", md: "100%  " }}
                  >
                    Trade
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex
        justify={"space-between"}
        align={"center"}
        w={"100%"}
        mt={"60px"}
        px={{ base: "12px", md: "32px" }}
        mx={"auto"}
      >
        <Text color={"#FFF"} fontSize={{ base: "12px", md: "20px" }}>
          {rowsPerPage * currentPage} / {data.length} Rows
        </Text>
        {/* Pagination controls */}
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(data.length / rowsPerPage)}
          paginate={paginate}
        />
      </Flex>
    </Box>
  );
};

export default BuyTable;

const Pagination = ({ currentPage, totalPages, paginate }: any) => {
  const pageNumbers = [];

  const maxPageButtons = 2;

  let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
  let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

  if (endPage === totalPages) {
    startPage = Math.max(endPage - maxPageButtons + 1, 1);
  }

  if (startPage > 1) {
    pageNumbers.push(1);
    if (startPage > 2) {
      pageNumbers.push("...");
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  if (endPage < totalPages) {
    if (endPage < totalPages - 1) {
      pageNumbers.push("...");
    }
    pageNumbers.push(totalPages);
  }
  return (
    <Flex align={"flex-start"} gap={{ base: "3px", md: "18px" }}>
      <PaginationButton
        onClick={() => {
          if (currentPage === 1) {
            return;
          } else {
            paginate(currentPage - 1);
          }
        }}
        disabled={currentPage === 1}
      >
        <Image src={downIcon} alt="downIcon" transform={"rotate(90deg)"} />
      </PaginationButton>
      {pageNumbers.map((number, index) => (
        <PaginationButton
          key={index}
          onClick={() => paginate(number)}
          active={number === currentPage}
        >
          {number}
        </PaginationButton>
      ))}
      <PaginationButton
        onClick={() => {
          if (currentPage === totalPages) {
            return;
          } else {
            paginate(currentPage + 1);
          }
        }}
        disabled={currentPage === totalPages}
      >
        <Image src={downIcon} alt="downIcon" transform={"rotate(-90deg)"} />
      </PaginationButton>
    </Flex>
  );
};

const PaginationButton = ({ onClick, disabled, active, children }: any) => {
  return (
    <Box
      p={{ base: "0px 1px", sm: "0px 5px", md: "0px 5px" }}
      gap={{ base: "4px", md: "10px" }}
      borderRadius={"12px"}
      border={"2px solid rgba(255,255,255,0.20)"}
      opacity={disabled ? 0.5 : 1}
      cursor={disabled ? "not-allowed" : "pointer"}
      bg={active && "rgba(255,255,255,0.1)"}
    >
      <Flex
        color={"#FFF"}
        fontSize={{ base: "10px", md: "18px" }}
        fontWeight={"600"}
        p={{ base: "5px 0px", md: "5px 10px" }}
        justify={"center"}
        align={"center"}
        gap={"4px"}
        w={{ base: "25px", md: "40px" }}
        h={{ base: "20px", md: "30px" }}
        onClick={onClick}
      >
        {children}
      </Flex>
    </Box>
  );
};
