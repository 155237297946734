import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import homeBg from "../assets/imgs/homeBg.svg";
import profileImg from "../assets/imgs/profile_img.svg";
import profileImg2 from "../assets/imgs/profile_img_2.svg";

import { IconSettings } from "@tabler/icons-react";
import redCircle from "../assets/imgs/red_circle.svg";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import TradeHistory from "../components/TradeHistoryTable";
import menuIcon from "../assets/imgs/menu_profile.svg";
import editIcon from "../assets/imgs/edit_profile.svg";
import shareIcon from "../assets/imgs/share_profile.svg";
import cmpsIcon from "../assets/imgs/cmps_Img.svg";
import OpenTradeTable from "../components/OpenTradeTable";

const tableData = Array.from({ length: 200 }, (_, index) => ({
  token: "SOL",
  dateBought: "12/4/17",
  dateSold: "12/4/17",
  holdTime: "31 days",
  priceBought: "0.021",
  priceSold: "0.042",
  return: "+100%",
  Conviction: redCircle,
  //   mostUsed: tokenImg,
}));

const openTradeData = Array.from({ length: 3 }, (_, index) => ({
  token: "SOL",
  dateBought: "12/4/17",
  holdTime: "31 days",
  priceBought: "0.021",
  livePrice: "0.042",
  return: "+100%",
  Conviction: redCircle,
  sellTrade: "Sell",
  //   mostUsed: tokenImg,
}));

const ProfilePage = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSeletedImage] = useState(profileImg);
  const [profileImage, setProfileImage] = useState(selectedImage);
  return (
    <Box
      maxW="1680px"
      mx="auto"
      px="20px"
      pb={{ base: "85px", md: "20px" }}
      bgImage={`url(${homeBg})`}
      bgSize={"cover"}
      bgPosition={"center"}
      bgRepeat="no-repeat"
    >
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent backgroundColor={"#161626"}>
          <ModalHeader>
            <Text
              color="#fff"
              fontSize={"30px"}
              fontFamily={"Abril Fatface"}
              fontWeight={"400"}
            >
              Select Profile
            </Text>

            <Text fontSize={"22px"} color="#fff">
              Select from Existing Avatars
            </Text>
          </ModalHeader>
          <ModalCloseButton color={"white"} mt={"10px"} />
          <Flex
            gap={"12px"}
            align={"center"}
            justifyContent={"center"}
            // mt={{ base: "12px", md: "30px", xl: "60px" }}
          >
            <Stack
              // maxW={{ base: "120px", md: "240px", xl: "400px" }}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"30px"}
            >
              <Image
                src={selectedImage}
                alt="profile"
                width={"324px"}
                height={{
                  base: "120px",
                  md: "240px",
                  xl: "300px",
                  "2xl": "308px",
                }}
              />
              <Box
                height={"4px"}
                width={"162px"}
                borderRadius={"10px"}
                bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
              />
              <Flex gap={"12px"} align={"center"} justify={"center"}>
                <Image
                  src={profileImage}
                  onClick={() => setSeletedImage(profileImg)}
                  alt="menu"
                  w={{ base: "44px", md: "100px" }}
                  h={{ base: "44px", md: "100px" }}
                />
                <Image
                  src={profileImg2}
                  onClick={() => setSeletedImage(profileImg2)}
                  alt="edit"
                  w={{ base: "44px", md: "100px" }}
                  h={{ base: "44px", md: "100px" }}
                />
                <Image
                  src={profileImg}
                  alt="share"
                  w={{ base: "44px", md: "100px" }}
                  h={{ base: "44px", md: "100px" }}
                />
                <Image
                  src={profileImg2}
                  alt="share"
                  w={{ base: "44px", md: "100px" }}
                  h={{ base: "44px", md: "100px" }}
                />
                <Image
                  src={profileImg}
                  alt="share"
                  w={{ base: "44px", md: "100px" }}
                  h={{ base: "44px", md: "100px" }}
                />
                <Image
                  src={profileImg2}
                  alt="share"
                  w={{ base: "44px", md: "100px" }}
                  h={{ base: "44px", md: "100px" }}
                />
                <Image
                  src={profileImg}
                  alt="share"
                  w={{ base: "44px", md: "100px" }}
                  h={{ base: "44px", md: "100px" }}
                />
                <Image
                  src={profileImg2}
                  alt="share"
                  w={{ base: "44px", md: "100px" }}
                  h={{ base: "44px", md: "100px" }}
                />
              </Flex>
              <Button
                // w={"100%"}
                display={"flex"}
                p={"24px"}
                gap={"20px"}
                //   flex={"1 0 0"}
                borderRadius={"10px"}
                bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
                color={"#FFF"}
                onClick={() => {
                  setProfileImage(selectedImage);
                  onClose();
                }}
                variant={"unstyled"}
                position="unset"
              >
                Upload Profile Picture
              </Button>
            </Stack>
          </Flex>
        </ModalContent>
      </Modal>
      <Box>
        <Header />
      </Box>
      <Box pt={{ base: "16px", md: "100px" }}>
        <Flex
          align="center"
          justify="center"
          flexDir="column"
          maxW="1250px"
          gap="26px"
          mx="auto"
        >
          <Flex
            align="center"
            fontSize={{ base: "24px", md: "32px", lg: "46px", "2xl": "60px" }}
            fontWeight="400"
            gap={{ base: "8px", lg: "12px", "2xl": "18px" }}
            fontFamily="Abril Fatface"
          >
            <Text color="#fff">Gladys</Text>
            <Text
              bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
              bgClip="text"
            >
              Profile
            </Text>
          </Flex>
          <Text
            fontSize={{ base: "12px", md: "18px", lg: "22px" }}
            fontWeight="400"
            color="#fff"
            textAlign="center"
          >
            Lorem ipsum dolor sit amet consectetur. Eget viverra arcu elementum
            tellus tortor facilisi nisl leo purus. Pulvinar vitae donec donec
            aenean ultrices vitae interdum laoreet non.
          </Text>
        </Flex>
      </Box>
      {/* <Flex
        align={"flex-start"}
        gap={{ base: "12px", md: "30px", xl: "60px" }}
        maxW="1580px"
        mt={{ base: "24px", md: "30px", xl: "60px" }}
        mx="auto"
        w={"100%"}
        mb={{ base: "12px", lg: "0px" }}
      >
        <Stack
          justify={"center"}
          align={"center"}
          gap={{ base: "12px", md: "24px" }}
        >
          <Image
            src={profileImg}
            alt="profileImg"
            // h={{ base: "188px", md: "280px", lg: "370px" }}
          />
          <Flex gap={"12px"} align={"flex-start"} justify={"center"}>
            <Image src={menuIcon} alt="menu" />
            <Image src={editIcon} alt="edit" />
            <Image src={shareIcon} alt="share" />
          </Flex>
        </Stack>
        <Stack
          align={"flex-start"}
          gap={{ base: "12px", md: "30px", xl: "60px" }}
        >
          <Flex
            w={"100%"}
            gap={{ base: "12px", md: "30px", xl: "60px" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Stack
              p={{ base: "12px", md: "24px" }}
              justify={"center"}
              align={"flex-start"}
              gap={{ base: "12px", md: "20px" }}
              borderRadius={{ base: "6px", md: "10px" }}
              bg={"rgba(255,255,255,00.05)"}
              //   flex={{ base: "1 0 0", md: "none" }}
            >
              <Stack
                justify={"center"}
                align={"flex-start"}
                gap={"12px"}
                alignSelf={"stretch"}
              >
                <Box>
                  <Text
                    fontSize={{ base: "12px", md: "22px" }}
                    textTransform={"uppercase"}
                    color={"#FFF"}
                    lineHeight={{ md: "40px" }}
                  >
                    Name
                  </Text>
                  <Text
                    fontFamily={"Abril Fatface"}
                    fontSize={{ base: "24px", md: "40px" }}
                    bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
                    bgClip="text"
                  >
                    Gladys
                  </Text>
                </Box>
                <Text
                  color={"#FFF"}
                  fontSize={{ base: "10px", md: "22px" }}
                  lineHeight={{ md: "40px" }}
                >
                  Lorem ipsum dolor sit amet consectetur. Et egestas
                  pellentesque amet amet nunc. Nulla mauris dolor sed vulputate
                  pretium.Lorem ipsum dolor sit amet consectetur. Et egestas
                  pellentesque amet amet nunc. Nulla mauris dolor sed vulputate
                  pretium. Quisque ipsum risus risus at. Vel auctor nulla neque
                  tellus sed id volutpat eu.
                </Text>
              </Stack>
            </Stack>
            <Button
              display={{ base: "none", md: "flex" }}
              p={{ base: "6px 12px", md: "24px" }}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"20px"}
              alignSelf={"stretch"}
              borderRadius={"10px"}
              bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
              height={{ md: "240px" }}
              color={"#FFF"}
              onClick={() => navigate("/settings")}
              // w={"100%"}
            >
              <Box
              // width={{ base: "24px", md: "40px" }}
              // height={{ base: "24px", md: "40px" }}
              >
                <IconSettings color="#FFF" />
              </Box>
            </Button>
          </Flex>

          <Flex
            align={"flex-start"}
            gap={{ md: "30px", xl: "60px" }}
            w={"100%"}
            display={{ base: "none", lg: "flex" }}
          >
            <ProfileCard text="Subscription Price" text2="100 $CMPS/Mo" />
            <ProfileCard text="Following" text2="21" />
            <ProfileCard
              text="Followers"
              text2="112"
              onClick={() => navigate("/followers")}
            />
          </Flex>
        </Stack>
      </Flex> */}
      <Flex
        gap={"12px"}
        align={"center"}
        mt={{ base: "12px", md: "30px", xl: "60px" }}
      >
        <Stack width={{ base: "240px", md: "240px", xl: "400px" }}>
          <Image onClick={onOpen} src={profileImage} alt="profile" />
          <Flex
            gap={{ base: "6px", md: "12px" }}
            align={"flex-start"}
            justify={"center"}
          >
            <Image
              src={menuIcon}
              alt="menu"
              width={{ base: "20px", md: "40px" }}
              height={{ base: "20px", md: "40px" }}
            />
            <Image
              src={editIcon}
              alt="edit"
              w={{ base: "20px", md: "40px" }}
              h={{ base: "20px", md: "40px" }}
            />
            <Image
              src={shareIcon}
              alt="share"
              w={{ base: "20px", md: "40px" }}
              h={{ base: "20px", md: "40px" }}
            />
          </Flex>
        </Stack>
        <Stack align={"flex-start"} gap={"60px"}>
          <Flex align={"center"} gap={"60px"}>
            <Stack
              p={"12px"}
              justify={"center"}
              gap={"12px"}
              borderRadius={"6px"}
              bg={"rgba(255,255,255,0.05)"}
              w={"100%"}
              h={"100%"}
            >
              <Box>
                <Text
                  fontSize={{ base: "12px", md: "18px", xl: "22px" }}
                  textTransform={"uppercase"}
                  color={"#FFF"}
                  lineHeight={{ md: "40px" }}
                >
                  Name
                </Text>
                <Text
                  fontFamily={"Abril Fatface"}
                  fontSize={{ base: "24px", md: "32px", xl: "40px" }}
                  bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
                  bgClip="text"
                >
                  Gladys
                </Text>
              </Box>
              <Text
                fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                color={"#FFF"}
                // bgClip="text"
              >
                Lorem ipsum dolor sit amet consectetur. Et egestas pellentesque
                amet amet nunc. Nulla mauris dolor sed vulputate pretium.
                Quisque ipsum risus risus at. Vel auctor nulla neque tellus sed
                id volutpat eu.
              </Text>
            </Stack>
            <Button
              display={{ base: "none", md: "flex" }}
              p={{ base: "6px 12px", md: "24px" }}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"20px"}
              alignSelf={"stretch"}
              borderRadius={"10px"}
              bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
              height={{ md: "200px" }}
              color={"#FFF"}
              onClick={() => navigate("/settings")}
              position="unset"
              // w={"100%"}
            >
              <Box
              // width={{ base: "24px", md: "40px" }}
              // height={{ base: "24px", md: "40px" }}
              >
                <IconSettings color="#FFF" />
              </Box>
            </Button>
          </Flex>
          <Flex
            align={"flex-start"}
            gap={{ md: "30px", xl: "60px" }}
            w={"100%"}
            display={{ base: "none", lg: "flex" }}
          >
            <ProfileCard text="Subscription Price" text2="100 $CMPS/Mo" />
            <ProfileCard text="Following" text2="21" />
            <ProfileCard
              text="Followers"
              text2="112"
              onClick={() => navigate("/followers")}
            />
          </Flex>
        </Stack>
      </Flex>
      <Button
        display={{ base: "flex", md: "none" }}
        p={{ base: "6px 12px", md: "24px" }}
        flexDir={"column"}
        mt={"12px"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"20px"}
        alignSelf={"stretch"}
        borderRadius={"10px"}
        bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
        height={{ md: "200px" }}
        color={"#FFF"}
        onClick={() => navigate("/settings")}
        w={"100%"}
        position="unset"
      >
        <Box
        // width={{ base: "24px", md: "40px" }}
        // height={{ base: "24px", md: "40px" }}
        >
          <IconSettings color="#FFF" />
        </Box>
      </Button>
      <Flex
        align={"center"}
        gap={{ base: "12px", md: "30px", xl: "60px" }}
        alignSelf={"stretch"}
        w={"100%"}
        mt={{ base: "12px", md: "60px" }}
        mb={"24px"}
        flexDirection={{ base: "column", md: "row" }}
      >
        <DetailCard
          wallet="Normal Wallet"
          balance="2000"
          onClick={() => navigate("/walletOverview")}
        />
        <DetailCard
          wallet="Auto Invest Wallet"
          balance="4000"
          onClick={() => navigate("/walletOverview")}
        />
        {/* <DetailCard /> */}
      </Flex>
      <Stack
        align={"center"}
        gap={"12px"}
        alignSelf={"stretch"}
        w={"100%"}
        display={{ base: "flex", lg: "none" }}
      >
        <ProfileCard text="Subscription Price" text2="100 $CMPS/Mo" />
        <ProfileCard text="Following" text2="21" />
        <ProfileCard
          text="Followers"
          text2="112"
          onClick={() => navigate("/followers")}
        />
      </Stack>
      <Flex
        maxW={"1580px"}
        mx={"auto"}
        p={"24px"}
        justify={"space-between"}
        align={"center"}
        borderRadius={"10px"}
        bg={"rgba(255,255,255,0.05)"}
        w={"100%"}
        mt={"60px"}
        flexWrap={"wrap"}
      >
        <TextStack text="completed trade" text2="12" />
        <TextStack text="best trade" text2="-" />
        <TextStack text="worst trade" text2="-" />
        <TextStack text="average return" text2="-" />
        <TextStack text="trades" text2="-" />
      </Flex>
      <Flex
        maxW={"1580px"}
        mx={"auto"}
        p={"24px"}
        justify={"space-between"}
        align={"center"}
        borderRadius={"10px"}
        bg={"rgba(255,255,255,0.05)"}
        w={"100%"}
        mt={"60px"}
        flexWrap={"wrap"}
      >
        <TextStack text="Winning trades" text2="-" />
        <TextStack text="losing trades" text2="-" />
        <TextStack text="open trades" text2="3" color="#00CD08" />
        <TextStack text="average open trades" text2="-9%" color="#F00" />
        <TextStack text="member since" text2="Feb,2024" />
      </Flex>
      <Stack
        mt={"60px"}
        align={"flex-start"}
        gap={{ base: "12px", md: "30px", xl: "60px" }}
        w={"100%"}
      >
        <Text
          color={"#FFF"}
          fontFamily={"Abril Fatface"}
          fontSize={{ base: "18px", md: "30px" }}
        >
          Open Trades
        </Text>
        <OpenTradeTable data={openTradeData} rowsPerPage={10} />
        <Center w={"100%"}>
          <Button
            variant={"unstyled"}
            w={{ base: "100%", sm: "250px" }}
            display={"flex"}
            p={"24px"}
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"20px"}
            //   flex={"1 0 0"}
            alignSelf={"stretch"}
            borderRadius={"10px"}
            bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
            color={"#FFF"}
            position="unset"
            onClick={() => navigate("/newTrade")}
          >
            Open a New Trade
          </Button>
        </Center>
      </Stack>
      <Stack mt={"60px"} align={"flex-start"} gap={"24px"} w={"100%"}>
        <Text
          color={"#FFF"}
          fontFamily={"Abril Fatface"}
          fontSize={{ base: "18px", md: "30px" }}
        >
          Trade History
        </Text>
        <Box w={"100%"}>
          <TradeHistory data={tableData} rowsPerPage={10} />
        </Box>
      </Stack>
      <Footer />
    </Box>
  );
};

export default ProfilePage;

const ProfileCard = ({ text, text2, onClick }: any) => {
  return (
    <Flex
      p={{ base: "12px", md: "24px" }}
      gap={{ md: "20px" }}
      align={"center"}
      flex={"1 0 0"}
      borderRadius={"10px"}
      bg={"rgba(255,255,255,0.05)"}
      w={"100%"}
      cursor={onClick ? "pointer" : ""}
      onClick={onClick ? onClick : ""}
    >
      <Stack
        justify={"center"}
        align={{ base: "center", md: "flex-start" }}
        gap={"-4px"}
        w={"100%"}
      >
        <Text
          color={"#FFF"}
          fontSize={{ base: "12px", md: "18px", xl: "22px" }}
          lineHeight={{ md: "40px" }}
          textTransform={"uppercase"}
        >
          {text}
        </Text>
        <Text
          bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
          bgClip="text"
          fontFamily={"Abril Fatface"}
          fontSize={{ base: "20px", md: "32px", xl: "40px" }}
        >
          {text2}
        </Text>
      </Stack>
    </Flex>
  );
};

const TextStack = ({ text, text2, color }: any) => {
  return (
    <Stack
      justify={"center"}
      align={{ base: "center", md: "flex-start" }}
      gap={"-4px"}
    >
      <Text
        color={"#FFF"}
        fontSize={{ base: "12px", md: "22px" }}
        lineHeight={{ md: "40px" }}
        textTransform={"uppercase"}
      >
        {text}
      </Text>
      <Text
        color={color || ""}
        bg={
          color
            ? ""
            : "var(--Gradient, linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%))"
        }
        bgClip={color ? "" : "text"}
        fontFamily={"Abril Fatface"}
        fontSize={{ base: "20px", md: "40px" }}
      >
        {text2}
      </Text>
    </Stack>
  );
};

const DetailCard = ({ wallet, balance, onClick }: any) => {
  return (
    <Flex
      cursor={"pointer"}
      onClick={onClick}
      p={"24px"}
      justify={"space-between"}
      align={"center"}
      flex={"1 0 0"}
      borderRadius={"16px"}
      bg={"rgba(255,255,255,0.05)"}
      w={"100%"}
    >
      <Box>
        <Text
          color={"#D9D9D9"}
          fontSize={{ base: "12px", md: "18px" }}
          lineHeight={{ md: "40px" }}
          textTransform={"uppercase"}
        >
          {wallet}
        </Text>
        <Text
          color={"#FFF"}
          fontSize={{ base: "22px", md: "40px" }}
          lineHeight={{ md: "40px" }}
          textTransform={"uppercase"}
          bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
          bgClip="text"
          fontFamily={"Abril Fatface"}
        >
          {balance}
        </Text>
      </Box>
      <Image src={cmpsIcon} alt="crypto-icon" />
    </Flex>
  );
};
