import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import StatCard from "./StatCard";
import { IconCopy } from "@tabler/icons-react";

const STATS = [
  {
    title: "USERS",
    number: "3,749",
  },
  {
    title: "TRADES",
    number: "1,465",
  },
  {
    title: "Max Supply",
    number: "100M",
  },
  {
    title: "CMPS Price",
    number: "$0.01",
  },
  {
    title: "Circulating Supply",
    number: "20M",
  },
];

function Hero() {
  return (
    <Box pt={{ base: "16px", md: "100px" }}>
      <Box
        display={{ base: "none", md: "inline-flex" }}
        transform={"rotate(90deg)"}
        p={"6px 12px"}
        alignItems={"center"}
        gap={"14px"}
        borderRadius={"8px"}
        bg={"rgba(255,255,255,0.10)"}
        position={"absolute"}
        right={-24}
      >
        {/* <Image src={tokenContractAdd} alt="address" /> */}
        <IconCopy color="#FFF" />
        <Text color={"#FFF"} fontSize={"18px"} lineHeight={"40px"}>
          Token Contract Address
        </Text>
      </Box>
      <Flex
        align="center"
        justify="center"
        flexDir="column"
        maxW="1250px"
        gap="16px"
        mx="auto"
      >
        <Flex
          align="center"
          fontSize={{ base: "24px", md: "50px", lg: "80px", "2xl": "100px" }}
          fontWeight="400"
          gap={{ base: "8px", lg: "12px", "2xl": "18px" }}
          fontFamily="Abril Fatface"
        >
          <Text color="#fff">Welcome to</Text>
          <Text
            bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
            bgClip="text"
          >
            Compass
          </Text>
        </Flex>
        <Text
          fontSize={{ base: "12px", md: "18px", lg: "22px" }}
          fontWeight="400"
          color="#fff"
          textAlign="center"
        >
          Compass is a revolutionary trading platform to help navigate you
          through the crypto space. Other details here like the token address,
          maybe a bunch of stats about users and average returns
        </Text>
      </Flex>
      <Flex
        mt={{ base: "24px", md: "80px" }}
        align="center"
        justify="center"
        wrap="wrap"
        gap={{ base: "10px", md: "40px" }}
      >
        {STATS?.map((stat, idx) => (
          <StatCard idx={idx} {...stat} />
        ))}
      </Flex>
    </Box>
  );
}

export default Hero;
