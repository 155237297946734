import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";

const RoadMapCard = ({ number, pic, text }: any) => {
  return (
    <Box w="280px">
      <Flex justify={"end"}>
        <Text
          color={"#FFF"}
          textAlign={"end"}
          fontFamily={"Abril Fatface"}
          fontSize={"40px"}
          letterSpacing={"0.4px"}
          mb={"-33px"}
          //   pl="12px"
          w={"fit-content"}
          //   border={"1px solid red"}
          //   p="6px"
          pl={"16px"}
          pb={"0px"}
          position={"relative"}
          zIndex={"2"}
          mr="-18px"
          bg={"#090A1A"}
          borderLeftRadius={"80%"}
        >
          {number}
        </Text>
      </Flex>
      <Stack
        p={"40px"}
        align={"flex-start"}
        gap={"20px"}
        flex={"1 0 0"}
        borderRadius={"26px"}
        bg={"rgba(255,255,255,0.05)"}
      >
        <Image src={pic} alt="pic" />
        <Text
          color={"#FFF"}
          fontFamily={"Abril Fatface"}
          fontSize={"30px"}
          letterSpacing={"0.3px"}
        >
          {text}
        </Text>
      </Stack>
    </Box>
  );
};

export default RoadMapCard;
