import {
  Container,
  Box,
  Input,
  Button,
  Image,
  Flex,
  Heading,
  Text,
  HStack,
  VStack,
  Divider,
} from "@chakra-ui/react";
import Cart from "../assets/imgs/cart.svg";
import WalletIco from "../assets/imgs/wallet.svg";
import ArrowUp from "../assets/imgs/arrow-up.svg";
import TradingViewWidget from "../components/TradingViewWidget";
import Header from "../components/Header";

function TradeDetails() {
  return (
    <Container maxW="container.xl">
      <Header />

      <Flex
        justifyContent={"space-between"}
        mt={10}
        flexWrap={{ base: "wrap", md: "nowrap" }}
      >
        <Box textAlign="center" position="relative">
          <Heading fontSize={20} color="white"> BTC / USDT</Heading>
          <Flex gap={3} alignItems={"center"}>
            <Image src={Cart} alt="cart" />
            <Text color={"white"}>$26,000</Text>
          </Flex>
        </Box>
        <Box mt={{ base: 4, md: 0 }} order={{ base: 3, lg: 1 }}>
          <Flex justifyContent={'space-between'} flex={'row'} gap={4}         flexWrap={{ base: "wrap", md: "nowrap" }}
>
            <Box>
              <Text >
                Last Price
              </Text>
              <Text color={"#9D3D3D"}>0.058505</Text>
            </Box>
            <Box>
              <Text >
                24h Change
              </Text>
              <Text color={"#426D3A"}>20%</Text>
            </Box>
            <Box>
              <Text >24h High</Text>
              <Text color={"#fff"}>0.060069</Text>
            </Box>
            <Box>
              <Text >24h Low</Text>
              <Text color={"#fff"}>0.056846</Text>
            </Box>
            <Box>
              <Text >
                24h Volume
              </Text>
              <Text color={"#fff"}>8532.10 M</Text>
            </Box>
          </Flex>
          <hr className="trade-details__hr" />
          <Flex justifyContent={'space-between'} flex={'row'} gap={4}         flexWrap={{ base: "wrap", md: "nowrap" }}
>
            <Box>
              <Text >
                Market Cap
              </Text>
              <Text color={"#fff"}>532, 422, 637495</Text>
            </Box>
            <Box>
              <Text >
                Volume (24h)
              </Text>
              <Text color={"#fff"}>19, 879, 245, 355</Text>
            </Box>
            <Box>
              <Text >
                Circulating Supply
              </Text>
              <Text color={"#fff"}>19,369,606 BTC</Text>
            </Box>
            <Box>
              <Text >
                Total Supply
              </Text>
              <Text color={"#fff"}>19,369,606 BTC</Text>
            </Box>
            <Box>
              <Text >
                Max. Supply
              </Text>
              <Text color={"#fff"}>21,000,000 BTC</Text>
            </Box>
          </Flex>
        </Box>
        <Box
          textAlign="center"
          mt={{ base: 3, md: 0 }}
          order={{ base: 1, md: 3 }}
        >
          <HStack gap={2}>
            <Image src={WalletIco} alt="wallet" />
            <Text color={"#3A4353"}>Wallet Balance (USD)</Text>
          </HStack>
          <Text color={"white"}>$59 510.78</Text>
        </Box>
      </Flex>

      <Box
        border={"1px solid rgba(255,255,255,0.20)"}
        borderRadius={"10px"}
        mt={10}
        padding={4}
      >
        <Flex
          justifyContent={"space-between"}
          gap={4}
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Box
            border={"1px solid rgba(255,255,255,0.20)"}
            borderRadius={"10px"}
            padding={2}
            width={{ base: "100%", lg: "30%" }}
          >
            <Heading color={"white"} fontSize={18}>
              Orders
            </Heading>
            <Flex
              backgroundColor={"#0A0909"}
              borderTopRadius={"10px"}
              padding={2}
              justifyContent={'space-between'}
              gap={4}
              mt={3}
            >
              <Box>
                <Text>Price</Text>
              </Box>
              <Box>
                <Text>Amount</Text>
              </Box>
              <Box>
                <Text>Total</Text>
              </Box>
            </Flex>
            <Box
              backgroundColor={"#111022"}
              padding={2}
              borderBottomRadius={"10px"}
            >
              {new Array(8).fill(1).map((_, idx) => (
                <Flex key={idx} justifyContent={'space-between'} gap={4} mt={3}>
                  <Text
                    display={"flex"}
                    flexDir={"row"}
                    color={"#C74A4A"}
                    fontSize={10}
                  >
                    0.093
                    <Text fontSize={10} color={"#fff"}>
                      765
                    </Text>
                  </Text>
                  <Box>
                    <Text fontSize={10} color={"#fff"}>
                      17.711
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize={10} color={"#fff"}>
                      1.64710513
                    </Text>
                  </Box>
                </Flex>
              ))}
            </Box>

            <Heading color={"#446F3D"} fontSize={18}>
              0.059132
            </Heading>
            <Box
              backgroundColor={"#111022"}
              padding={2}
              borderBottomRadius={"10px"}
            >
              {new Array(8).fill(1).map((_, idx) => (
                <Flex key={idx} justifyContent={'space-between'} gap={4} mt={3}>
                  <Box>
                    <Text
                      display={"flex"}
                      flexDir={"row"}
                      color={"#446F3D"}
                      fontSize={10}
                    >
                      0.093
                      <Text fontSize={10} color={"#fff"}>
                        765
                      </Text>
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize={10} color={"#fff"}>
                      17.711
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize={10} color={"#fff"}>
                      1.64710513
                    </Text>
                  </Box>
                </Flex>
              ))}
            </Box>
          </Box>
          <Box>
            <Flex
              flexDir={"column"}
              gap={4}
              height={400}
              backgroundColor={"#151414"}
              borderRadius={10}
              px={2}
              py={4}
            >
              <Flex
                flexDirection={{ base: "column", sm: "row" }}
                justifyContent={"space-between"}
                mt={4}
              >
                <HStack>
                  <Text color={"#fff"}>DEFT/ETH</Text>
                  <Text color={"#fff"}>1h</Text>
                  <Text color={"#fff"}>Uniswap</Text>
                </HStack>
                <HStack>
                  <Text color={"#72BF65"}>0.0682 H</Text>
                  <Text color={"#E55454"}>0.0682 L</Text>
                  <Text color={"#E55454"}>C 0.0682</Text>
                </HStack>
              </Flex>

              <Text
                display={"flex"}
                flexDir={"row"}
                alignItems={"center"}
                color={"#fff"}
                gap={3}
              >
                Volume <Text color={"#72BF65"}> $223K</Text>
              </Text>
              <TradingViewWidget />
            </Flex>

            <Flex gap={4} mt={10} flexDirection={{ base: "column", sm: "row" }}>
              <Flex>
                <VStack alignItems={"flex-start"} width={"100%"}>
                  <HStack>
                    <Text>Avbl</Text>
                    <Text color={"#fff"}>-BTC</Text>
                  </HStack>
                  <Box
                    border={"1px solid rgba(255,255,255,0.20)"}
                    borderRadius={"10px"}
                    paddingY={10}
                    paddingX={5}
                    width={"100%"}
                  >
                    <Box>
                      <VStack gap={4}>
                        <Input
                          placeholder="59453.00"
                          padding={4}
                          width={"100%"}
                        />
                        <Input placeholder="1.00" padding={4} width={"100%"} />
                        <Button width={"100%"}>Buy</Button>
                      </VStack>
                    </Box>
                  </Box>
                </VStack>
              </Flex>
              <Flex>
                <VStack alignItems={"flex-start"} width={"100%"}>
                  <HStack>
                    <Text>Avbl</Text>
                    <Text color={"#fff"}>-BTC</Text>
                  </HStack>
                  <Box
                    border={"1px solid rgba(255,255,255,0.20)"}
                    borderRadius={"10px"}
                    paddingY={10}
                    paddingX={5}
                    width={"100%"}
                  >
                    <Box>
                      <VStack gap={4}>
                        <Input
                          placeholder="59453.00"
                          padding={4}
                          width={"100%"}
                        />
                        <Input placeholder="1.00" padding={4} width={"100%"} />
                        <Button width={"100%"}>Buy</Button>
                      </VStack>
                    </Box>
                  </Box>
                </VStack>
              </Flex>
            </Flex>
          </Box>

          <Flex
            border={"1px solid rgba(255,255,255,0.20)"}
            borderRadius={"10px"}
            padding={2}
            flexDir={"column"}
            gap={4}
          >
            <Heading fontSize={18} color={"#fff"}>
              Top Trending
            </Heading>
            <Box>
              <HStack gap={2} mb={2}>
                <Input
                  placeholder="Search"
                  backgroundColor={"#352746"}
                  borderRadius={10}
                  border={0}
                />
              </HStack>
              <HStack justifyContent={"space-between"} alignItems={"center"}>
                <Text color={"#636475"} fontSize={11}>
                  Margin
                </Text>
                <Text color={"#636475"} fontSize={11}>
                  BUSD
                </Text>
                <Text color={"#636475"} fontSize={11}>
                  USDT
                </Text>
                <Text color={"#636475"} fontSize={11}>
                  BTC
                </Text>
                <Text color={"#636475"} fontSize={11}>
                  BNB
                </Text>
                <Text color={"#636475"} fontSize={11}>
                  ALTSv
                </Text>
                <Text color={"#636475"} fontSize={11}>
                  FIAT
                </Text>
              </HStack>

              <Flex justifyContent={'space-between'} gap={4} mt={3}>
                <Box>
                  <Text fontSize={9} color="#fff">
                    Pair
                  </Text>
                </Box>
                <Box>
                  <Text fontSize={9} color="#fff">
                    Price
                  </Text>
                </Box>
                <Box>
                  <Text fontSize={9} color="#fff">
                    Change
                  </Text>
                </Box>
              </Flex>
              {new Array(8).fill(1).map((_, idx) => (
                <>
                  <Flex
                    key={idx}
                    justifyContent={'space-between'}
                    gap={4}
                    mt={3}
                  >
                    <Box>
                      <Text fontSize={10} color={"#fff"}>
                        <span>APE</span>/BTC
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize={10} color={"#fff"}>
                        0.00020052
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize={10} color={"#fff"}>
                        -0.34%
                      </Text>
                    </Box>
                  </Flex>
                  <Divider width={"100%"} />
                </>
              ))}
            </Box>

            <Heading fontSize={18} color={"#fff"}>
              Market Trades
            </Heading>
            <Box>
              <HStack
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={4}
                mt={3}
              >
                <Box>
                  <Text fontSize={9} color={"#4C5158"}>
                    Price(USD)
                  </Text>
                </Box>
                <Box>
                  <Text fontSize={9} color={"#4C5158"}>
                    Amount(BTC)
                  </Text>
                </Box>
                <Box>
                  <Text fontSize={9} color={"#4C5158"}>
                    Time
                  </Text>
                </Box>
              </HStack>
              <Text backgroundColor={"#080916"} textAlign={"right"}>
                May 25, 2023
              </Text>
              {new Array(8).fill(1).map((_, idx) => (
                <HStack
                  key={idx}
                  gap={4}
                  mt={3}
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Box>
                    <HStack gap={2}>
                      <Image src={ArrowUp} alt="arrow" width={"10px"} />
                      <Text fontSize={10} color={"#fff"}>
                        $59453.00
                      </Text>
                    </HStack>
                  </Box>
                  <Box>
                    <Text fontSize={10} color={"#fff"}>
                      0.19229215
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize={10} color={"#fff"}>
                      17:54:59
                    </Text>
                  </Box>
                </HStack>
              ))}
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Container>
  );
}

export default TradeDetails;
