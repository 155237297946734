import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import homeBg from "../assets/imgs/homeBg.svg";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons-react";
import Footer from "../components/Footer";
import downIcon from "../assets/imgs/down_icon.svg";

const NewTrade = () => {
  const navigate = useNavigate();

  return (
    <Box
      maxW="1680px"
      mx="auto"
      px="20px"
      pb={{ base: "85px", md: "20px" }}
      bgImage={`url(${homeBg})`}
      //   bgSize={"cover"}
      bgPosition={"center"}
      bgRepeat="no-repeat"
    >
      <Box display={{ base: "none", md: "block" }}>
        <Header />
      </Box>
      <Box pt={{ base: "16px", md: "100px" }}>
        <Flex
          align="center"
          justify="center"
          flexDir="column"
          maxW="1250px"
          gap="26px"
          mx="auto"
        >
          <Center
            gap={"6px"}
            fontSize={"20px"}
            color={"#FFF"}
            display={{ base: "none", md: "flex" }}
          >
            <Text
              textDecorationLine={"underline"}
              bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
              bgClip="text"
              cursor={"pointer"}
              onClick={() => navigate("/profilePage")}
            >
              Profile
            </Text>
            <Text>/</Text>
            <Text>Open a New Trade</Text>
          </Center>
          <Flex
            display={{ base: "flex", md: "none" }}
            gap={"6px"}
            fontSize={"14x"}
            color={"#FFF"}
            align={"center"}
            justify={"flex-start"}
            w={"100%"}
          >
            <IconArrowLeft
              cursor={"pointer"}
              onClick={() => navigate("/profilePage")}
            />
            <Text w={"100%"} textAlign={"center"}>
              Open a New Trade
            </Text>
          </Flex>
          <Flex
            align="center"
            fontSize={{ base: "24px", md: "32px", lg: "46px", "2xl": "60px" }}
            fontWeight="400"
            gap={{ base: "8px", lg: "12px", "2xl": "18px" }}
            fontFamily="Abril Fatface"
          >
            <Text color="#fff">Open</Text>
            <Text
              bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
              bgClip="text"
            >
              Trade
            </Text>
          </Flex>
        </Flex>
      </Box>
      <Stack
        mt={{ base: "24px", md: "90px" }}
        align={"center"}
        gap={{ base: "24px", md: "60px" }}
        alignSelf={"stretch"}
        maxW={"1250px"}
        mx={"auto"}
      >
        <SimpleGrid
          columns={[1, 2]}
          spacing={{ base: "24px", md: "60px" }}
          w={"100%"}
        >
          <FilterCard
            options={["10-days", "50-days", "90-days"]}
            defaultOption="Select Token"
          />
          <FilterCard
            options={["10-days", "50-days", "90-days"]}
            defaultOption="Select Chain"
          />
        </SimpleGrid>
        <SimpleGrid columns={1} w={"100%"}>
          <FilterCard
            options={["10-days", "50-days", "90-days"]}
            defaultOption="Select Chain"
          />
        </SimpleGrid>
        <Button
          variant={"unstyled"}
          w={{ base: "100%", md: "251px" }}
          p={"10px 24px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
          borderRadius={{ base: "6px", md: "12px" }}
          bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
          color={"#FFF"}
          fontSize={{ base: "12px", md: "20px" }}
          lineHeight={{ md: "40px" }}
          position="unset"
        >
          Open Trade Now
        </Button>
      </Stack>
      <Footer />
    </Box>
  );
};

export default NewTrade;

const FilterCard = ({ options, defaultOption }: any) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const handleSelectOption = (option: any) => {
    setSelectedOption(option);
  };

  return (
    <Menu isLazy>
      <MenuButton>
        <Flex
          p={{ base: "10px 12px", md: "10px 24px" }}
          justifyContent={"space-between"}
          flex={"1 0 0"}
          alignItems={"center"}
          bg={"rgba(255,255,255,0.05)"}
          borderRadius={"12px"}
        >
          <Text
            color="#FFF"
            w={"fit-content"}
            fontSize={{ base: "12px", md: "16px" }}
          >
            {selectedOption}
          </Text>
          <Image src={downIcon} />
        </Flex>
      </MenuButton>
      <MenuList
        bg="#1D1E2D"
        p="0px"
        border="none"
        //   width={{ base: "90px", md: "160px" }}
        w={"100%"}
      >
        {options.map((option: any) => (
          <MenuItem
            key={option}
            bg="rgba(255,255,255,0.01)"
            onClick={() => handleSelectOption(option)}
          >
            <Flex mr="120px" align="center" gap="4px">
              <Text color="#FFF" lineHeight="40px">
                {option}
              </Text>
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
