import {
  Flex,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

import React from "react";
import downIcon from "../assets/imgs/down_icon.svg";

const SwapCard = ({ text, icon, name }: any) => {
  return (
    <Flex
      p={{ base: "16px", md: "40px" }}
      flexDir="column"
      align="center"
      gap="20px"
      flex="1 0 0"
      maxW={{ base: "272px", md: "510px" }}
      borderRadius="26px"
      bg="rgba(255,255,255,0.10)"
    >
      <Text
        color="#FFF"
        fontSize={{ base: "14px", md: "22px" }}
        lineHeight={{ base: "normal", md: "40px" }}
        textTransform="uppercase"
      >
        {text}
      </Text>
      <Image
        src={icon}
        alt="_Img"
        w={{ base: "60px", md: "120px" }}
        h={{ base: "60px", md: "120px" }}
      />
      <InputGroup
        display="flex"
        padding="10px 24px"
        justifyContent="space-between"
        alignItems="center"
        borderRadius={{ base: "4px", md: "12px" }}
        bg="rgba(255,255,255,0.05)"
      >
        <Input
          placeholder="0.00"
          variant="unstyled"
          color="#FFF"
          fontSize={{ base: "14px", md: "20px" }}
          lineHeight={{ md: "40px" }}
          _placeholder={{
            color: "#FFF",
          }}
        />
        <InputRightElement p="10px 24px" h="100%">
          <Menu isLazy>
            <MenuButton>
              <Flex mr={{ base: "80px", md: "120px" }} align="center" gap="4px">
                <Image src={icon} w={{ base: "16px", md: "28px" }} />
                <Text
                  color="#FFF"
                  lineHeight={{ md: "40px" }}
                  fontSize={{ base: "12px", md: "16px" }}
                >
                  {name}
                </Text>
                {/* <IconChevronDown width={"29px"} /> */}
                <Image src={downIcon} />
              </Flex>
            </MenuButton>
            <MenuList
              bg="#1D1E2D"
              p="0px"
              border="none"
              // position={"absolute"}
              // zIndex={"23333"}
            >
              {/* MenuItems are not rendered unless Menu is open */}
              <MenuItem bg="rgba(255,255,255,0.01)">
                <Flex mr="120px" align="center" gap="4px">
                  <Image src={icon} w="28px" />
                  <Text
                    color="#FFF"
                    lineHeight={{ md: "40px" }}
                    fontSize={{ base: "12px", md: "16px" }}
                  >
                    {name}
                  </Text>
                </Flex>
              </MenuItem>
              <MenuItem bg="rgba(255,255,255,0.01)">
                <Flex mr="120px" align="center" gap="4px">
                  <Image src={icon} w="28px" />
                  <Text
                    color="#FFF"
                    lineHeight={{ md: "40px" }}
                    fontSize={{ base: "12px", md: "16px" }}
                  >
                    {name}
                  </Text>
                </Flex>
              </MenuItem>
              <MenuItem bg="rgba(255,255,255,0.01)">
                <Flex mr="120px" align="center" gap="4px">
                  <Image src={icon} w="28px" />
                  <Text
                    color="#FFF"
                    lineHeight={{ md: "40px" }}
                    fontSize={{ base: "12px", md: "16px" }}
                  >
                    {name}
                  </Text>
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
};

export default SwapCard;
