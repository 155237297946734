import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import logo from "../assets/imgs/Logo.svg";
import linkdln from "../assets/imgs/linkdln_icon.svg";
import twitter from "../assets/imgs/twitter_icon.svg";
import facebook from "../assets/imgs/facebook_icon.svg";
import youtube from "../assets/imgs/youtube_icon.svg";
import insta from "../assets/imgs/insta_icon.svg";

const Footer = () => {
  return (
    <Flex
      mt={{ base: "48px", lg: "150px" }}
      maxW={{ lg: "1405px" }}
      justify={"space-between"}
      pl={{ lg: "50px" }}
      align={"center"}
      mb={{ base: "30px", lg: "50px" }}
      direction={{ base: "column", lg: "row" }}
      gap={"16px"}
    >
      <Image
        src={logo}
        alt="logo"
        w={{ base: "69px", lg: "143px" }}
        h={{ base: "80px", lg: "164px" }}
        order={{ base: 1, lg: 2 }}
      />
      <Text
        color={"#FFF"}
        fontSize={{ base: "12px", lg: "22px" }}
        lineHeight={{ base: "normal", lg: "40px" }}
        order={{ base: 2, lg: 1 }}
      >
        Copyrights © 2024, CMPS. All Rights Reserved
      </Text>
      <Flex gap={"12px"} order={{ base: 2, lg: 3 }}>
        <Image src={linkdln} alt="linkdln" />
        <Image src={twitter} alt="twitter" />
        <Image src={insta} alt="insta" />
        <Image src={facebook} alt="facebook" />
        <Image src={youtube} alt="youtube" />
      </Flex>
    </Flex>
  );
};

export default Footer;
