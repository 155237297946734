import { Button, Flex, Image } from "@chakra-ui/react";
import React from "react";
import Logo from "../assets/imgs/Logo.svg";
import MenuItem from "./MenuItem";
import CopyIco from "../assets/imgs/copy_ico.svg";
import HomeActive from "../assets/imgs/home_active.svg";
import HomeInactive from "../assets/imgs/home_inactive.svg";
import LeaderActive from "../assets/imgs/leader_active.svg";
import LeaderInactive from "../assets/imgs/leader_inactive.svg";
import FilterActive from "../assets/imgs/filter_active.svg";
import FilterInactive from "../assets/imgs/filter_inactive.svg";
import CartActive from "../assets/imgs/cart_active.svg";
import CartInactive from "../assets/imgs/cart_inactive.svg";
import ProfileActive from "../assets/imgs/profile_active.svg";
import ProfileInactive from "../assets/imgs/profile_inactive.svg";
import MobileMenuItem from "./MobileMenuItem";
import { useQuery } from "@tanstack/react-query";

const MENU = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Buy $CMPS",
    path: "/buy",
  },
  {
    name: "Roadmap",
    path: "",
  },
  {
    name: "Whitepaper",
    path: "",
  },
  {
    name: "Leaderboard",
    path: "/leaderboard",
  },
  {
    name: "Filters",
    path: "/filters",
  },
  {
    name: "Stake $CMPS",
    path: "/staking",
  },
  {
    name: "Profile",
    path: "/profilePage",
  },
  {
    name: "Login",
    path: "/login",
  },
];

const MOBILE_MENU = [
  {
    name: "Home",
    path: "/",
    activeIco: HomeActive,
    inActiveIco: HomeInactive,
  },
  {
    name: "Leaderboard",
    path: "/leaderboard",
    activeIco: LeaderActive,
    inActiveIco: LeaderInactive,
  },
  {
    name: "Filters",
    path: "/filters",
    activeIco: FilterActive,
    inActiveIco: FilterInactive,
  },
  {
    name: "Stake $CMPS",
    path: "/staking",
    activeIco: CartActive,
    inActiveIco: CartInactive,
  },
  {
    name: "Profile",
    path: "/profilePage",
    activeIco: ProfileActive,
    inActiveIco: ProfileInactive,
  },
  {
    name: "Login",
    path: "/login",
    activeIco: ProfileActive,
    inActiveIco: ProfileInactive,
  },
];

function Header() {
  const { data: token } = useQuery({
    queryKey: ["token"],
    queryFn: () => localStorage.getItem("token"),
  });
  return (
    <>
      <Flex align="center" justify="space-between" pt="10px">
        <Image
          src={Logo}
          alt="logo"
          cursor="pointer"
          width={100}
          height={100}
        />
        <Flex
          align="center"
          display={{ base: "none", md: "flex" }}
          gap={{ md: "20px", lg: "40px", "2xl": "80px" }}
        >
          {MENU?.filter((menu: any) => {
            if (menu.name === "Login" && token) {
              return false;
            } else if (menu.name === "Profile" && !token) {
              return false;
            }
            return true;
          })?.map((menu: any, idx: number) => (
            <MenuItem menu={menu} idx={idx} />
          ))}
        </Flex>
        <Flex display={{ base: "flex", md: "none" }} align="center" gap="16px">
          <Button
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="31px"
            width="65px"
            bg="rgba(255, 255, 255, 0.10)"
            borderRadius="6px"
            color="#fff"
            fontSize="10px"
            fontWeight="400"
            _hover={{
              bg: "rgba(255, 255, 255, 0.10)",
            }}
            p="0"
            position="unset"
          >
            Buy $CMPS
          </Button>
          <Button
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="31px"
            width="65px"
            bg="rgba(255, 255, 255, 0.10)"
            borderRadius="6px"
            color="#fff"
            fontSize="10px"
            fontWeight="400"
            _hover={{
              bg: "rgba(255, 255, 255, 0.10)",
            }}
            p="0"
            position="unset"
          >
            Roadmap
          </Button>
          <Button
            height="32px"
            width="32px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="6px"
            bg="rgba(255, 255, 255, 0.10)"
            _hover={{
              bg: "rgba(255, 255, 255, 0.10)",
            }}
            p="0"
            position="unset"
          >
            <Image src={CopyIco} alt="copy" height="20px" width="20px" />
          </Button>
        </Flex>
      </Flex>
      <Flex
        display={{ base: "flex", md: "none" }}
        bg="#161626"
        p="0px 10px 10px 10px"
        justify="space-between"
        align="center"
        pos="fixed"
        zIndex={5}
        bottom="0"
        left="0"
        borderRadius="10px 10px 0px 0px"
        width="100%"
      >
        {MOBILE_MENU?.filter((menu: any) => {
          if (menu.name === "Login" && token) {
            return false;
          } else if (menu.name === "Profile" && !token) {
            return false;
          }
          return true;
        })?.map((menu, idx) => (
          <MobileMenuItem mobileMenu={menu} idx={idx} />
        ))}
      </Flex>
    </>
  );
}

export default Header;
