import { Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";
import RoadMapCard from "./RoadMapCard";
import moneyImg from "../assets/imgs/money_img.svg";
import marketing from "../assets/imgs/digital_marketing.svg";
import management from "../assets/imgs/project_management.svg";
import list from "../assets/imgs/list_img.svg";

const RoadMap = () => {
  return (
    <Flex
      mt={{ base: "48px", lg: "150px" }}
      maxW={{ lg: "1405px" }}
      flexDir={"column"}
      align={"center"}
      gap={{ base: "24px", lg: "100px" }}
      mx={"auto"}
    >
      <Stack align={"center"} gap={"0px"}>
        <Text
          color={"#FFF"}
          fontSize={{ base: "12px", lg: "22px" }}
          lineHeight={{ base: "normal", lg: "40px" }}
          textTransform={"uppercase"}
        >
          Our Goals
        </Text>
        <Text
          fontFamily={"Abril Fatface"}
          fontSize={{ base: "24px", lg: "50px" }}
          letterSpacing={{ base: "0.24px", lg: "0.5px" }}
          bgClip="text"
          bgGradient="linear(to-r, #EE683D, #F38D3F)"
        >
          Road Map
        </Text>
      </Stack>
      <Flex
        align={{ base: "center", lg: "flex-start" }}
        maxW={{ lg: "1405px" }}
        // pl={{ lg: "46px" }}
        w={"100%"}
        mx={"auto"}
        justify={"space-between"}
        flexWrap={"wrap"}
        direction={{ base: "column", lg: "row" }}
      >
        <RoadMapCard number="01" pic={moneyImg} text="Funding" />
        <RoadMapCard number="02" pic={marketing} text="Marketing" />
        <RoadMapCard number="03" pic={management} text="TGE" />
        <RoadMapCard number="04" pic={list} text="CEX Listings" />
      </Flex>
    </Flex>
  );
};

export default RoadMap;
