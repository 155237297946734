import React, { useEffect, useState } from "react";
import homeBg from "../assets/imgs/homeBg.svg";
import {
  Box,
  Button,
  Center,
  Flex,
  PinInput,
  PinInputField,
  Text,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const pass = searchParams.get("pass");
  const [timer, setTimer] = useState(60);
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  const displayTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  useEffect(() => {
    const countdown = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [timer]);
  return (
    <Box
      maxW="1680px"
      mx="auto"
      px="20px"
      pb={{ base: "85px", md: "20px" }}
      bgImage={`url(${homeBg})`}
      bgSize="cover"
      bgPosition={"center"}
      bgRepeat="no-repeat"
      h={"100vh"}
    >
      <Center maxW={"650px"} mx={"auto"} h={"100%"}>
        <Flex
          p={{ base: "12px", md: "24px" }}
          gap={{ md: "12px" }}
          align={"center"}
          flex={"1 0 0"}
          borderRadius={"10px"}
          bg={"rgba(255,255,255,0.05)"}
          w={"100%"}
          flexDirection={"column"}
          // cursor={onClick ? "pointer" : ""}
          // onClick={onClick ? onClick : ""}
        >
          <Text
            color={"#FFF"}
            fontSize={{ base: "12px", md: "18px", xl: "22px" }}
            lineHeight={{ md: "40px" }}
            textTransform={"uppercase"}
            bg="linear-gradient(134deg, #EE683D -22.41%, #F38D3F 124.32%)"
            bgClip="text"
            mb={"20px"}
          >
            {pass
              ? "Please Enter OTP to change password"
              : "Please Enter OTP to verify email"}
          </Text>

          <Flex mb="10px" mt="40px" gap="12px" h="50px">
            <PinInput
              size="20px"
              focusBorderColor="#EE683D"
              placeholder="0"
              onChange={(e) => {
                // setPin(e);
              }}
            >
              {[...Array(6)].map((_, index) => (
                <PinInputField
                  key={index}
                  borderRadius="8px"
                  fontSize="32px"
                  color="#FFF"
                  fontWeight={500}
                  //   className={jakarta?.className}
                  _placeholder={{ color: "grey" }}
                />
              ))}
            </PinInput>
          </Flex>
          <Flex mb="30px" justify={"flex-end"} w={"100%"}>
            {/* <CustomText text={displayTime} weight={500} /> */}
            <Text color={"#FFF"}>{timer > 0 ? displayTime : "Resend Otp"}</Text>
          </Flex>

          <Center>
            <Button
              // maxW={"160px"}
              variant={"unstyled"}
              display="flex"
              p="10px 24px"
              justifyContent="center"
              alignItems="center"
              gap="10px"
              flex={{ lg: "1 0 0" }}
              borderRadius="12px"
              border="1px solid #EE683D"
              fontSize={{ base: "12px", lg: "20px" }}
              lineHeight="40px"
              bgGradient="linear(to-tr, #EE683D, #F38D3F)"
              bgClip="text"
              position="unset"
              w={"600px"}
              mt={"20px"}
              onClick={
                pass ? () => navigate("/resetPassword") : () => navigate("/")
              }
            >
              Confirm
            </Button>
          </Center>
        </Flex>
      </Center>
    </Box>
  );
};

export default VerifyEmail;
