import React, { useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

const FilterTable = ({ data, rowsPerPage, onClick }: any) => {
  const [currentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: "return",
    direction: "desc",
  });

  const sortedData = [...data].sort((a, b) => {
    console.log(a);
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);

  const handleSort = (key: any) => {
    if (sortConfig.key === key) {
      // If already sorting by this key, reverse direction
      setSortConfig({
        key,
        direction: sortConfig.direction === "asc" ? "desc" : "asc",
      });
    } else {
      // If sorting by a different key, set new key and default direction
      setSortConfig({ key, direction: "desc" });
    }
  };
  console.log(sortConfig);

  return (
    <Box position={"relative"}>
      <TableContainer
        color={"#FFF"}
        mx={"auto"}
        w={"100%"}
        px={{ base: "16px", md: "24px", xl: "30px" }}
        pt={{ base: "16px", md: "32px", xl: "60px" }}
        sx={{
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            display: "none",
          },
          "&::-webkit-scrollbar-track": {
            display: "none",
          },
          "@media screen and (max-width: 62em)": {
            // for screens from base to lg
            "&::after": {
              content: "''",
              position: "absolute",
              top: "0",
              right: "0",
              width: "12px", // Adjust width as needed
              height: "100%",
              background:
                "linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,1))",
            },
          },
        }}
      >
        <Table
          variant="unstyled"
          overflowX={"hidden"}
          // size={{ base: "sm", md: "xs" }}
          size={"sm"}
        >
          <Thead>
            <Tr>
              <Th
                onClick={() => handleSort("name")}
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
                cursor={"pointer"}
                // pr={"45px"}
              >
                Trader
              </Th>
              <Th
                onClick={() => handleSort("followers")}
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
                cursor={"pointer"}
              >
                Followers
              </Th>
              <Th
                onClick={() => handleSort("trades")}
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
                cursor={"pointer"}
              >
                Trades
              </Th>
              <Th
                onClick={() => handleSort("bestTrade")}
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
                cursor={"pointer"}
              >
                Best Trade
              </Th>
              <Th
                onClick={() => handleSort("worstTrade")}
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
                cursor={"pointer"}
              >
                Worst Trade
              </Th>
              <Th
                onClick={() => handleSort("winRate")}
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
                cursor={"pointer"}
              >
                Win Rate
              </Th>
              <Th
                onClick={() => handleSort("holdTime")}
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
                cursor={"pointer"}
              >
                Avg Hold Time
              </Th>

              <Th
                onClick={() => handleSort("return")}
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
                cursor={"pointer"}
              >
                Avg Return
              </Th>
              <Th
                onClick={() => handleSort("subscriptionPrice")}
                color={"#FFF"}
                fontFamily={"Poppins"}
                fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                textTransform={"capitalize"}
                fontWeight={"400"}
                cursor={"pointer"}
              >
                Subscription Price
              </Th>
            </Tr>
          </Thead>
          <Box mt={{ base: "0px", md: "60px" }}></Box>
          <Tbody>
            {currentRows.map((rowData: any, index: any) => (
              <Tr
                key={index}
                bg={index % 2 !== 0 ? "rgba(255,255,255,0.05)" : "none"}
                borderRadius={"12px"}
              >
                <Td>
                  <Flex
                    align={{ base: "center", md: "flex-start" }}
                    gap={"11px"}
                    my={{
                      base: index % 2 === 0 ? "8px" : "0px",
                      md: index % 2 === 0 ? "32px" : "0px",
                    }}
                    cursor={"pointer"}
                    onClick={onClick}
                  >
                    <Image
                      src={rowData.trader.imageSrc}
                      alt="trader"
                      w={{ base: "25px", md: "32px", xl: "40px" }}
                    />
                    <Text
                      color={"#7F7F7F"}
                      fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                      fontWeight={"400"}
                      lineHeight={"40px"}
                    >
                      {rowData.trader.name}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                    fontWeight={"400"}
                    lineHeight={"40px"}
                    textAlign={"center"}
                    my={{
                      base: index % 2 === 0 ? "8px" : "0px",
                      md: index % 2 === 0 ? "32px" : "0px",
                    }}
                  >
                    {" "}
                    {rowData.followers}
                  </Text>
                </Td>
                <Td>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                    fontWeight={"400"}
                    lineHeight={"40px"}
                    textAlign={"center"}
                    my={{
                      base: index % 2 === 0 ? "8px" : "0px",
                      md: index % 2 === 0 ? "32px" : "0px",
                    }}
                  >
                    {rowData.trades}
                  </Text>
                </Td>
                <Td>
                  <Text
                    color={"#00CD08"}
                    fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                    fontWeight={"400"}
                    lineHeight={"40px"}
                    textAlign={"center"}
                    my={{
                      base: index % 2 === 0 ? "8px" : "0px",
                      md: index % 2 === 0 ? "32px" : "0px",
                    }}
                  >
                    {rowData.bestTrade}
                  </Text>
                </Td>
                <Td>
                  <Text
                    color={"#F00"}
                    fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                    fontWeight={"400"}
                    textAlign={"center"}
                    lineHeight={"40px"}
                    my={{
                      base: index % 2 === 0 ? "8px" : "0px",
                      md: index % 2 === 0 ? "32px" : "0px",
                    }}
                  >
                    {rowData.worstTrade}
                  </Text>
                </Td>
                <Td>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                    fontWeight={"400"}
                    lineHeight={"40px"}
                    textAlign={"center"}
                    my={{
                      base: index % 2 === 0 ? "8px" : "0px",
                      md: index % 2 === 0 ? "32px" : "0px",
                    }}
                  >
                    {rowData.winRate}
                  </Text>
                </Td>
                <Td>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                    fontWeight={"400"}
                    lineHeight={"40px"}
                    textAlign={"center"}
                    my={{
                      base: index % 2 === 0 ? "8px" : "0px",
                      md: index % 2 === 0 ? "32px" : "0px",
                    }}
                  >
                    {rowData.holdTime}
                  </Text>
                </Td>
                <Td>
                  <Text
                    color={index % 2 === 0 ? "#00CD08" : "#F00"}
                    fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                    fontWeight={"400"}
                    textAlign={"center"}
                    lineHeight={"40px"}
                    my={{
                      base: index % 2 === 0 ? "8px" : "0px",
                      md: index % 2 === 0 ? "32px" : "0px",
                    }}
                  >
                    {rowData.return}
                  </Text>
                </Td>
                <Td>
                  <Text
                    color={"#7F7F7F"}
                    fontSize={{ base: "10px", md: "16px", xl: "20px" }}
                    fontWeight={"400"}
                    textAlign={"center"}
                    lineHeight={"40px"}
                    my={{
                      base: index % 2 === 0 ? "8px" : "0px",
                      md: index % 2 === 0 ? "32px" : "0px",
                    }}
                  >
                    {rowData.subscriptionPrice}
                  </Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Center>
        <Button
          position="unset"
          variant={"unstyled"}
          display={"flex"}
          mt={"60px"}
          p={"10px 24px"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
          borderRadius={"12px"}
          color={"#FFF"}
          fontSize={"20px"}
          lineHeight={"40px"}
          css={{
            background:
              "linear-gradient(134deg, rgba(238, 104, 61, 1) -22.41%, rgba(243, 141, 63, 1) 124.32%)",
          }}
        >
          Load More
        </Button>
      </Center>
    </Box>
  );
};

export default FilterTable;
