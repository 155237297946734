import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";

let tvScriptLoadingPromise: Promise<Event> | null;

const TradingViewWidget: React.FC = () => {
  const onLoadScriptRef = useRef<() => void>();

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

    return () => {
      onLoadScriptRef.current = undefined;
    };

    function createWidget() {
      if (document.getElementById("tradingview_ccda1") && "TradingView" in window) {
        // Asserting the type of window.TradingView to any
        const TradingView: any = window.TradingView;
        new TradingView.widget({
          autosize: true,
          symbol: "NASDAQ:AAPL",
          interval: "D",
          timezone: "Etc/UTC",
          theme: "dark",
          style: "1",
          locale: "en",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          allow_symbol_change: true,
          container_id: "tradingview_ccda1",
        
        });
      }
    }
  }, []);

  return (
      <Box height={'100%'} id="tradingview_ccda1"  />
  );
};

export default TradingViewWidget;
